@use "mixins" as *;

@use "variables" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password,
.umbraco-forms-field.dropdown {
  position: relative;
  max-width: 500px;

  label:not(.checkboxLabel) {
    line-height: 1;
    white-space: normal;
  }

  input:not([type="checkbox"]):not([type="radio"]),
  select {
    appearance: none;
    position: relative;
    white-space: normal;
    line-height: 1;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  select {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWF4WU1pZCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOiM0NDQ7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5hcnJvd3M8L3RpdGxlPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQuOTUiIGhlaWdodD0iMTAiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMS40MSA0LjY3IDIuNDggMy4xOCAzLjU0IDQuNjcgMS40MSA0LjY3Ii8+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjMuNTQgNS4zMyAyLjQ4IDYuODIgMS40MSA1LjMzIDMuNTQgNS4zMyIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: 25px 50px;
    &::-ms-expand {
      display: none;
    }
  }

  .description {
    margin-top: 6px;
    font-size: 0.84em;
    opacity: 0.92;

    > *:first-child {
      margin-top: 0 !important;
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.fieldWrapper,
.umbraco-forms-field.longanswer {
  textarea {
    appearance: none;
    position: relative;
    white-space: normal;
    line-height: 1;
  }
}

.controlWrapper,
.adg-umbraco-forms-controlwrapper {
  border-bottom: 2px solid $lightGrey;
  margin-bottom: 15px;
  padding-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  .control {
    display: flex;
    align-items: center;
  }

  label {
    font-size: 1.2em;
    white-space: nowrap;
    cursor: pointer;
  }

  .description {
    margin-top: 6px;
    font-size: 0.84em;
    opacity: 0.92;

    > *:first-child {
      margin-top: 0 !important;
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

//These style is just used for the Honeypot feature. These styles keep the field from being seen by humans and they should not be changed or removed.
.fieldWrapper.enrolleeName {
  opacity: 0;
  @include absolute;
  width: 0;
  height: 0;
  z-index: -1;
}

// END BASE STYLES

.styledFieldset,
.umbraco-forms-fieldset {
  position: relative;
  border: 0px;
  padding: 0px;
  border-top: 1px solid rgba($light, 0.5);
  border-radius: 0px;
  padding-top: 10px;

  legend,
  .groupLegend {
    padding: 8px 10px 4px 10px;
    border-radius: $radius;
    background: $primary;
    color: $yellow;
    text-align: left;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8em;
  }

  .groupLegend {
    display: inline-block;
    margin-bottom: 9px;
  }

  .fieldsetGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    .fieldWrapper,
    .controlWrapper {
      margin: 0;
    }
  }

  > :last-child {
    margin-bottom: 0 !important;
  }
}

.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password,
.umbraco-forms-field.dropdown {
  margin: 10px auto;

  label:not(.checkboxLabel) {
    position: absolute;
    top: 10px;
    left: 9px;
    z-index: 2;
    display: block;
    margin: 0 0 5px;
    border: 0;
    padding: 0;
    font-size: 0.8em;
    font-family: $secondaryFont;
    color: $darkGrey;
  }

  input:not([type="checkbox"]):not([type="radio"]),
  select {
    position: relative;
    display: block;
    width: 100%;
    min-height: 52px;
    margin: 0;
    border: 2px solid lighten($primary, 25%);
    padding: 23px 8px 6px;
    border-radius: $radius;
    background: $light;
    color: $basic;
    text-align: left;
  }

  select {
    padding-top: 24px;
  }
  button {
    position: absolute !important;
    color: $light;
    top: 0;
    right: 0;
    height: 53px;
    width: 40px;
    @include flexCenter;
    transform: scale(0.8);
    border-radius: $radius;
    background: $primary;
    border: none;
    padding: 0;
    cursor: pointer;
    &[aria-pressed="true"] {
      .icon:after {
        content: "";
        width: 3px;
        height: 20px;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        transform: skewX(-45deg);
        background: $light;
      }
    }
    .icon {
      font-size: 1.2em;
    }
  }
}

.fieldWrapper,
.umbraco-forms-field.longanswer {
  textarea {
    position: relative;
    display: block;
    width: 100%;
    min-height: 52px;
    margin: 0;
    border: 2px solid lighten($primary, 25%);
    padding: 6px 8px 6px;
    border-radius: $radius;
    background: $light;
    color: $basic;
    text-align: left;
  }
}

form,
.formWrap {
  .error {
    display: block;
    margin: 4px 0;
    padding: 2px 4px;
    text-align: center;
    @include bgWithContrast($wrong);
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //

@media (max-width: $xs) {
  .styledFieldset .fieldsetGrid {
    grid-template-columns: none;
  }
}

@media (max-width: $xxs) {
  .controlWrapper {
    label {
      font-size: 1em;
      font-weight: 700;
      white-space: normal;
    }
  }
}

@media (max-width: $tiny) {
  .controlWrapper {
    label {
      font-size: 0.9em;
    }
  }
}
