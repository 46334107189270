@use "mixins" as *;

@use "variables" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

.rte {
  h4,
  h5 {
    margin: 0.8em 0 0.5em;
  }
  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;
  }
}

// END BASE STYLES //

.rte {
  p {
    font-family: $basicFont;
    font-size: 1em;
  }

  h3.heading {
    margin: 25px 0 10px;
    font-size: 1.65em;
    font-weight: 800;
    color:$primary;
    border-bottom: 1px solid $secondary;
    padding-bottom: 3px;
    & + .headingList{
      margin-top: 25px;
    }
  }

  h4.subheading {
    margin: 25px 0 0px;
    font-size: 1.3em;
    font-weight: 600;
    color:$primary;
    // border-bottom: 1px solid $secondary;
    padding-bottom: 3px;
    & + .headingList{
      margin-top: 25px;
    }
  }

  .headingList,
  .subheadingList {
    margin: 20px 0 10px;
    padding-bottom: 3px;
    // border-bottom: 2px darkblue ;
     border:double 2px $secondary;
     border-width: 0px 0px 4px 0px;
    font-weight: 500;
    @include colorWithContrast($primary);
    font-size: 1.05em;
    line-height: 1.2em;
   // margin-left: 20px;
    //padding-left: 20px; // maybe remove
    // border-top: 1px solid $secondary;
  }



  .largeText {
    position: relative;
    // padding-top: 13px;
    margin-bottom: 25px;
    display: block;
    font-size: 1.5em;
    line-height: 1.4em;
    font-family: $secondaryFont;
    font-weight: 700;
  }

  .highlight {
    @include bgWithContrast($lightGrey);
  }

  ul {
    padding: 0;

    > li {
      margin-left: 20px;
      margin-bottom: 5px;

      &::marker {
        font-size: 1.2em;
        color: $primary;
      }
    }
  }

  ol {
    padding: 0;
    list-style-type: none;
    counter-reset: counter;

    > li {
      position: relative;
      counter-increment: counter;
      margin-left: 40px;
      margin-bottom: 5px;

      &:before {
        content: counter(counter) ". ";
        position: absolute;
        right: calc(100% + 8px);
        color: $primary;
        font-weight: bold;
      }
    }
  }

  a {

  }

  &.calloutBold {
    margin: 30px 0px;
    padding: 10px;
    border-radius: 0px;
    background: $light;
    border: 1px solid $secondary;
    color:$primary;
    background-image:$pinkGradientRadial;
    font-weight: 600;
    .inner{
      border: 1px solid $secondary;
      padding: 15px;
      background: $light;
      > *:first-child {
        margin-top: 0 !important;
      }
      > *:last-child {
        margin-bottom: 0 !important;
      }
    }
   
  }

  &.calloutSubtle {
    margin: 25px auto;
    padding: 20px 20px 0px;
    position: relative;
    width: 100%;
    font-weight: 500;
   
   .inner{
    > *:first-child {
      margin-top: 0 !important;
    }
    > *:last-child {
      margin-bottom: 0 !important;
    }
   }
    // &:before, &:after{
    //   content: '';
    //   height: 3px;
    //   width: 100%;
    //   position: absolute;
    //   left: 0px;
    // }
    // &:before{ top: 0px;@include linearGradient; }
    // &:after{bottom: 0px;@include linearGradient($deg: -90deg);}
    &:before, &:after{
      content: '';
      position: absolute;
      top: 0px;
      left:0px;
    }
    &:before{ width: 40px; height: 5px;@include linearGradient($deg: 90deg); }
    &:after{height: 40px; width: 5px;@include linearGradient($deg: 180deg);}

   
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //
