@use "mixins" as *;
@use "variables" as *;

#membership .formWrap.maintenance {
  background: $secondary;
  border-color: $light;

  .formArea {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background: transparent;

    .rte {
      color: $light;
    }

    .icon {
      flex-shrink: 0;
      flex-grow: 0;
      width: 75px;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3em;
      background: $primary;
      border: 2px solid $light;
      border-radius: 50%;
      color: $light;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: $xs) {
  #membership .formWrap.maintenance {
    padding: 15px;

    .formArea {
      flex-direction: column-reverse;
      justify-content: center;

      .icon {
        width: 55px;
        height: 55px;
        font-size: 2.25em;
        padding: 0;
        margin-bottom: 15px;
      }
    }
  }
}
