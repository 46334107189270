@font-face {
    font-family: 'fiu-eel';
    src:  url('../fonts/fiu-eel.eot?cwmd5m');
    src:  url('../fonts/fiu-eel.eot?cwmd5m#iefix') format('embedded-opentype'),
      url('../fonts/fiu-eel.woff2?cwmd5m') format('woff2'),
      url('../fonts/fiu-eel.ttf?cwmd5m') format('truetype'),
      url('../fonts/fiu-eel.woff?cwmd5m') format('woff'),
      url('../fonts/fiu-eel.svg?cwmd5m#fiu-eel') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'fiu-eel' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-errorPage:before {
    content: "\e915";
  }
  .icon-slideThisOrThatQuiz:before {
    content: "\e913";
  }
  .icon-slideForm:before {
    content: "\e914";
  }
  .icon-slideWordSearch:before {
    content: "\e905";
  }
  .icon-slideMatchingGame:before {
    content: "\e906";
  }
  .icon-slideSurvey:before {
    content: "\e907";
  }
  .icon-accessibility:before {
    content: "\e908";
  }
  .icon-slideSelfDirected:before {
    content: "\e909";
  }
  .icon-slideReveal:before {
    content: "\e90a";
  }
  .icon-slideMasterTest:before {
    content: "\e90b";
  }
  .icon-slideChecklist:before {
    content: "\e90c";
  }
  .icon-slideCopy:before {
    content: "\e90d";
  }
  .icon-star:before {
    content: "\6b";
  }
  .icon-tasks:before {
    content: "\65";
  }
  .icon-zoom-in:before {
    content: "\5a";
  }
  .icon-external-link:before {
    content: "\e904";
  }
  .icon-slideview:before {
    content: "\e901";
  }
  .icon-slideVideo:before {
    content: "\6e";
  }
  .icon-quickview:before {
    content: "\6a";
  }
  .icon-pdf:before {
    content: "\70";
  }
  .icon-spinner:before {
    content: "\46";
  }
  .icon-achievement:before {
    content: "\e903";
  }
  .icon-printer:before {
    content: "\6f";
  }
  .icon-pause:before {
    content: "\e911";
  }
  .icon-glossary:before {
    content: "\e902";
  }
  .icon-arrow-next:before {
    content: "\6d";
  }
  .icon-arrow-previous:before {
    content: "\6c";
  }
  .icon-lock:before {
    content: "\63";
  }
  .icon-x:before {
    content: "\62";
  }
  .icon-play:before {
    content: "\e910";
  }
  .icon-logout:before {
    content: "\e90e";
  }
  .icon-slideQuiz:before {
    content: "\73";
  }
  .icon-copy:before {
    content: "\4d";
  }
  .icon-mail:before {
    content: "\e912";
  }
  .icon-home:before {
    content: "\e90f";
  }
  .icon-menu:before {
    content: "\e900";
  }
  .icon-learn-more:before {
    content: "\68";
  }
  .icon-slideItemsOfInterest:before {
    content: "\68";
  }
  .icon-err:before {
    content: "\45";
  }
  .icon-check:before {
    content: "\61";
  }
  .icon-complete:before {
    content: "\7a";
  }
  .icon-faq:before {
    content: "\78";
  }
  .icon-grid:before {
    content: "\79";
  }
  .icon-link:before {
    content: "\64";
  }
  .icon-search:before {
    content: "\66";
  }
  