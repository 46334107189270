@font-face {
  font-family: "fiu-eel";
  src: url("../fonts/fiu-eel.eot?cwmd5m");
  src: url("../fonts/fiu-eel.eot?cwmd5m#iefix") format("embedded-opentype"), url("../fonts/fiu-eel.woff2?cwmd5m") format("woff2"), url("../fonts/fiu-eel.ttf?cwmd5m") format("truetype"), url("../fonts/fiu-eel.woff?cwmd5m") format("woff"), url("../fonts/fiu-eel.svg?cwmd5m#fiu-eel") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "fiu-eel" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-errorPage:before {
  content: "\e915";
}

.icon-slideThisOrThatQuiz:before {
  content: "\e913";
}

.icon-slideForm:before {
  content: "\e914";
}

.icon-slideWordSearch:before {
  content: "\e905";
}

.icon-slideMatchingGame:before {
  content: "\e906";
}

.icon-slideSurvey:before {
  content: "\e907";
}

.icon-accessibility:before {
  content: "\e908";
}

.icon-slideSelfDirected:before {
  content: "\e909";
}

.icon-slideReveal:before {
  content: "\e90a";
}

.icon-slideMasterTest:before {
  content: "\e90b";
}

.icon-slideChecklist:before {
  content: "\e90c";
}

.icon-slideCopy:before {
  content: "\e90d";
}

.icon-star:before {
  content: "k";
}

.icon-tasks:before {
  content: "e";
}

.icon-zoom-in:before {
  content: "Z";
}

.icon-external-link:before {
  content: "\e904";
}

.icon-slideview:before {
  content: "\e901";
}

.icon-slideVideo:before {
  content: "n";
}

.icon-quickview:before {
  content: "j";
}

.icon-pdf:before {
  content: "p";
}

.icon-spinner:before {
  content: "F";
}

.icon-achievement:before {
  content: "\e903";
}

.icon-printer:before {
  content: "o";
}

.icon-pause:before {
  content: "\e911";
}

.icon-glossary:before {
  content: "\e902";
}

.icon-arrow-next:before {
  content: "m";
}

.icon-arrow-previous:before {
  content: "l";
}

.icon-lock:before {
  content: "c";
}

.icon-x:before {
  content: "b";
}

.icon-play:before {
  content: "\e910";
}

.icon-logout:before {
  content: "\e90e";
}

.icon-slideQuiz:before {
  content: "s";
}

.icon-copy:before {
  content: "M";
}

.icon-mail:before {
  content: "\e912";
}

.icon-home:before {
  content: "\e90f";
}

.icon-menu:before {
  content: "\e900";
}

.icon-learn-more:before {
  content: "h";
}

.icon-slideItemsOfInterest:before {
  content: "h";
}

.icon-err:before {
  content: "E";
}

.icon-check:before {
  content: "a";
}

.icon-complete:before {
  content: "z";
}

.icon-faq:before {
  content: "x";
}

.icon-grid:before {
  content: "y";
}

.icon-link:before {
  content: "d";
}

.icon-search:before {
  content: "f";
}

@font-face {
  font-family: "furoreregular";
  src: url("../fonts/furore-webfont.eot");
  src: url("../fonts/furore-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/furore-webfont.woff2") format("woff2"), url("../fonts/Furore-webfont.woff") format("woff"), url("../fonts/Furore-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*---TEXT AND FONT---*/
/*---BACKGROUND---*/
/*---POSITIONING---*/
/*---CONTENT---*/
/*---LISTS---*/
/*---BORDER ARROWS---*/
/**Forms**/
*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible, *.focus-visible {
  outline-offset: 4px;
  outline: 2px dashed #00FFFF;
}

body[data-reduce-animation=true] *,
body[data-reduce-animation=true] *::before,
body[data-reduce-animation=true] *::after {
  animation-duration: 0.01ms !important;
  animation-iteration-count: 1 !important;
  transition-duration: 0.01ms !important;
  scroll-behavior: auto !important;
}

html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

html[data-active-zone=slides],
html[data-active-zone=home] {
  scrollbar-color: #f8c93e #f5f5f5;
}
html[data-active-zone=slides]::-webkit-scrollbar-track,
html[data-active-zone=home]::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
html[data-active-zone=slides]::-webkit-scrollbar,
html[data-active-zone=home]::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  background-color: #f5f5f5;
}
html[data-active-zone=slides]::-webkit-scrollbar-thumb,
html[data-active-zone=home]::-webkit-scrollbar-thumb {
  background-color: #f8c93e;
}

html[data-active-zone=slides] {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.1;
  font-size: 16px;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #081E3f;
}
body.toolsOpen {
  overflow-x: hidden;
}
body.menuOut {
  background: #fff;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

p {
  margin: 0.35em 0 0.75em;
  line-height: 1.55em;
}

ol,
ul {
  text-align: left;
  margin: 0.4em 0 0.75em;
}

li {
  line-height: 1.5em;
}

iframe {
  width: 100%;
  margin-bottom: 10px;
  border: 2px solid #ebebec;
}

a {
  color: #081E3f;
  text-decoration: underline;
  text-decoration-color: #D92D8A;
}
@media (hover: hover) and (any-pointer: fine) {
  a:hover {
    text-decoration: none;
  }
}
[data-browser=ie] a:hover {
  text-decoration: none;
}
.ie a:hover {
  text-decoration: none;
}
a.focus-visible {
  text-decoration: none;
}
a:focus-visible {
  text-decoration: none;
}
a .targetBlankNotifier {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

willis-zone {
  display: block;
}

[inert] {
  overflow: hidden;
  pointer-events: none;
}

html[data-active-dialog]:not([data-active-dialog=""]) {
  overflow: hidden;
}

willis-dialog:not([data-open=true]) {
  display: none !important;
}

.dialogBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.slide .dialogBackdrop {
  height: 100%;
  padding-left: calc(50px + 15px);
}

.dialogInner {
  position: relative;
}

[data-dialog-style=modal] {
  display: flex;
  align-items: center;
  overflow-y: scroll;
}
[data-dialog-style=modal] div[role=dialog] {
  width: 580px;
  margin: auto;
}
[data-dialog-style=modal] div[role=dialog].reportNeeded {
  width: 80%;
  max-width: 1000px;
}

[data-dialog-style=largeModal] {
  display: flex;
  align-items: center;
  overflow-y: scroll;
}
[data-dialog-style=largeModal] div[role=dialog] {
  width: 800px;
  max-width: 100vw;
  margin: auto;
}

[data-dialog-style=shade] {
  overflow-y: scroll;
}
[data-dialog-style=shade] div[role=dialog] {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
[data-dialog-style=shade] div[role=dialog] .dialogLabel {
  max-width: 800px;
}
[data-dialog-style=shade] div[role=dialog] .dialogContent {
  max-width: 800px;
}
[data-dialog-style=shade] div[role=dialog] .dialogActions {
  max-width: 800px;
}

[data-dialog-style^=sidebar] {
  display: flex;
  align-items: stretch;
  overflow-y: scroll;
  overflow-x: hidden;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarLeft] {
  justify-content: flex-start;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarRight] {
  justify-content: flex-end;
}
[data-dialog-style^=sidebar] div[role=dialog] {
  width: 440px;
}

[data-dialog-style^=full] {
  display: flex;
  align-items: stretch;
  overflow: hidden;
}
[data-dialog-style^=full] div[role=dialog] {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  [data-dialog-style^=sidebar] div[role=dialog] {
    width: 100%;
    min-width: 0;
  }
  [data-dialog-style=modal] div[role=dialog] {
    width: 100%;
  }
}
div[data-dialog-animation-speed=glacial] {
  animation-duration: 3000ms;
}

div[data-dialog-animation-speed=verySlow] {
  animation-duration: 1000ms;
}

div[data-dialog-animation-speed=slow] {
  animation-duration: 800ms;
}

div[data-dialog-animation-speed=medium] {
  animation-duration: 500ms;
}

div[data-dialog-animation-speed=fast] {
  animation-duration: 300ms;
}

div[data-dialog-animation-speed=veryFast] {
  animation-duration: 200ms;
}

.dialogBackdrop {
  animation-fill-mode: both;
  animation-duration: 200ms;
}
.dialogBackdrop.in {
  animation-name: fadeIn;
}
.dialogBackdrop.out {
  animation-name: fadeOut;
}

div[role=dialog] {
  position: relative;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
div[data-dialog-animation-name=fadeIn] {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
div[data-dialog-animation-name=fadeOut] {
  animation-name: fadeOut;
}

@keyframes fadeTopIn {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=fadeTopIn] {
  animation-name: fadeTopIn;
}

@keyframes fadeTopOut {
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 0;
    transform: translateY(-50%);
  }
}
div[data-dialog-animation-name=fadeTopOut] {
  animation-name: fadeTopOut;
}

@keyframes fadeBottomIn {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=fadeBottomIn] {
  animation-name: fadeBottomIn;
}

@keyframes fadeBottomOut {
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 0;
    transform: translateY(50%);
  }
}
div[data-dialog-animation-name=fadeBottomOut] {
  animation-name: fadeBottomOut;
}

@keyframes fadeRightIn {
  from {
    opacity: 0;
    transform: translateX(50%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=fadeRightIn] {
  animation-name: fadeRightIn;
}

@keyframes fadeRightOut {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(50%);
  }
}
div[data-dialog-animation-name=fadeRightOut] {
  animation-name: fadeRightOut;
}

@keyframes fadeLeftIn {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=fadeLeftIn] {
  animation-name: fadeLeftIn;
}

@keyframes fadeLeftOut {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(50%);
  }
}
div[data-dialog-animation-name=fadeLeftOut] {
  animation-name: fadeLeftOut;
}

@keyframes slideTopIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=slideTopIn] {
  animation-name: slideTopIn;
}

@keyframes slideTopOut {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}
div[data-dialog-animation-name=slideTopOut] {
  animation-name: slideTopOut;
}

@keyframes slideBottomIn {
  from {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0%);
  }
}
div[data-dialog-animation-name=slideBottomIn] {
  animation-name: slideBottomIn;
}

@keyframes slideBottomOut {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(200%);
  }
}
div[data-dialog-animation-name=slideBottomOut] {
  animation-name: slideBottomOut;
}

@keyframes slideRightIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=slideRightIn] {
  animation-name: slideRightIn;
}

@keyframes slideRightOut {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(150%);
    opacity: 0;
  }
}
div[data-dialog-animation-name=slideRightOut] {
  animation-name: slideRightOut;
}

@keyframes slideLeftIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
div[data-dialog-animation-name=slideLeftIn] {
  animation-name: slideLeftIn;
}

@keyframes slideLeftOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
div[data-dialog-animation-name=slideLeftOut] {
  animation-name: slideLeftOut;
}

.dialogBackdrop {
  background: rgba(0, 0, 0, 0.7);
}

.dialogContent {
  margin: 10px 0;
}

.dialogActions.generated button.closeDialog .icon:before {
  font-family: "fiu-eel";
  content: "b";
}

button.closeDialog .icon:before {
  font-family: "fiu-eel";
  font-size: 18px;
  content: "b";
}

.dialogLabel h2 {
  margin-bottom: 15px;
}

[data-dialog-style=modal] {
  padding: 20px;
}
.safari [data-dialog-style=modal] {
  padding: 20px 20px 150px;
}
[data-dialog-style=modal] .dialogInner {
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  color: #081E3f;
}
[data-dialog-style=modal] button.closeDialog {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px 8px;
  border: 0px;
  line-height: 1.1em;
  text-align: center;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  outline-offset: 0;
  text-decoration: none;
  transition: all 0.15s;
  background-color: #081E3f;
  color: white;
}
[data-dialog-style=modal] button.closeDialog .icon {
  font-family: "fiu-eel";
}
@media (hover: hover) and (any-pointer: fine) {
  [data-dialog-style=modal] button.closeDialog:hover {
    text-decoration: none;
    background-color: #FFCC00;
    color: #081E3f;
    cursor: pointer;
  }
}
[data-browser=ie] [data-dialog-style=modal] button.closeDialog:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.ie [data-dialog-style=modal] button.closeDialog:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
[data-dialog-style=modal] button.closeDialog.focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
[data-dialog-style=modal] button.closeDialog:focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
[data-dialog-style=modal] button.closeDialog:focus-visible {
  outline: 2px solid #f8c93e;
}
[data-dialog-style=modal] button.closeDialog[disabled], [data-dialog-style=modal] button.closeDialog[aria-disabled=true] {
  background: #54545a;
  color: #fff;
  text-decoration: none;
}
@media (hover: hover) and (any-pointer: fine) {
  [data-dialog-style=modal] button.closeDialog:hover {
    background-color: #f8c93e;
    color: #081E3f;
    border-color: #f8c93e;
  }
}
[data-browser=ie] [data-dialog-style=modal] button.closeDialog:hover {
  background-color: #f8c93e;
  color: #081E3f;
  border-color: #f8c93e;
}
.ie [data-dialog-style=modal] button.closeDialog:hover {
  background-color: #f8c93e;
  color: #081E3f;
  border-color: #f8c93e;
}
[data-dialog-style=modal] button.closeDialog .icon {
  display: none;
}

.safari [data-dialog-style=largeModal] {
  padding: 20px 20px 150px;
}
[data-dialog-style=largeModal] .dialogInner {
  border-radius: 4px;
  padding: 20px;
  background-color: #fff;
  color: #081E3f;
}
[data-dialog-style=largeModal] .dialogLabel {
  text-align: center;
  margin-bottom: 25px;
  border-bottom: 1px solid #081E3f;
  padding: 5px 10px 20px;
}
[data-dialog-style=largeModal] .dialogLabel h2 {
  margin: 0;
  padding: 0;
  font-size: 1.4em;
  color: #081E3f;
  font-family: "furoreregular", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: normal;
}

[data-dialog-style=shade] .dialogInner {
  border-radius: 0 0 4px 4px;
  padding: 20px;
  background-color: #081E3f;
  background-image: linear-gradient(175deg, #081E3f 40%, #051328 100%);
  color: white;
}
[data-dialog-style=shade] .dialogInner .dialogLabel {
  text-align: center;
  text-transform: uppercase;
}
[data-dialog-style=shade] .dialogInner .dialogActions {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  gap: 7px;
}
[data-dialog-style=shade] .dialogInner .closeDialog {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px 8px;
  border: 0px;
  line-height: 1.1em;
  text-align: center;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  outline-offset: 0;
  text-decoration: none;
  transition: all 0.15s;
  background-color: #f8c93e;
  color: #081E3f;
}
[data-dialog-style=shade] .dialogInner .closeDialog .icon {
  font-family: "fiu-eel";
}
@media (hover: hover) and (any-pointer: fine) {
  [data-dialog-style=shade] .dialogInner .closeDialog:hover {
    text-decoration: none;
    background-color: #FFCC00;
    color: #081E3f;
    cursor: pointer;
  }
}
[data-browser=ie] [data-dialog-style=shade] .dialogInner .closeDialog:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.ie [data-dialog-style=shade] .dialogInner .closeDialog:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
[data-dialog-style=shade] .dialogInner .closeDialog.focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
[data-dialog-style=shade] .dialogInner .closeDialog:focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
[data-dialog-style=shade] .dialogInner .closeDialog:focus-visible {
  outline: 2px solid #f8c93e;
}
[data-dialog-style=shade] .dialogInner .closeDialog[disabled], [data-dialog-style=shade] .dialogInner .closeDialog[aria-disabled=true] {
  background: #54545a;
  color: #fff;
  text-decoration: none;
}

[data-dialog-style^=sidebar] .dialogInner {
  min-height: 100%;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarLeft] .dialogInner {
  border-radius: 0 4px 4px 0;
  padding: 20px;
}
[data-dialog-style^=sidebar][data-dialog-style=sidebarRight] .dialogInner {
  border-radius: 4px 0 0 4px;
  padding: 20px;
}

[data-dialog-style^=full] .dialogInner {
  padding: 20px;
}

.simpleDialog {
  background-color: #fff;
  color: #081E3f;
  border-radius: 4px;
}

#returnMessage .closeDialog .icon {
  display: none;
}

@media (max-width: 670px) {
  [data-dialog-style^=sidebar][data-dialog-style=sidebarLeft] .dialogInner {
    border-radius: 0;
  }
  [data-dialog-style^=sidebar][data-dialog-style=sidebarRight] .dialogInner {
    border-radius: 0;
  }
  [data-dialog-style=modal] div[role=dialog].reportNeeded {
    width: 100%;
  }
}
#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: all 500ms, top 0.75s;
}
#background .bgImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}
#background .bgImg[data-bg-pos="top left"] {
  background-position: 0% 0%;
}
#background .bgImg[data-bg-pos="top middle"] {
  background-position: 50% 0;
}
#background .bgImg[data-bg-pos="top right"] {
  background-position: 100% 0%;
}
#background .bgImg[data-bg-pos="center left"] {
  background-position: 0% 50%;
}
#background .bgImg[data-bg-pos=center] {
  background-position: 50% 50%;
}
#background .bgImg[data-bg-pos="center right"] {
  background-position: 100% 50%;
}
#background .bgImg[data-bg-pos="bottom left"] {
  background-position: 0% 100%;
}
#background .bgImg[data-bg-pos="bottom middle"] {
  background-position: 50% 100%;
}
#background .bgImg[data-bg-pos="bottom right"] {
  background-position: 100% 100%;
}
#background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #ebebec;
}
#background:before {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.65);
  transition: opacity 300ms, width 0.5s;
  opacity: 1;
  z-index: 10;
}
#background:after {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.65);
  transition: opacity 300ms, width 0.5s;
  opacity: 1;
  z-index: 10;
}
.inSection #background {
  top: 125%;
}
[data-is-loading=true] #background {
  opacity: 0;
}

.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password,
.umbraco-forms-field.dropdown {
  position: relative;
  max-width: 500px;
}
.fieldWrapper label:not(.checkboxLabel),
.umbraco-forms-field.shortanswer label:not(.checkboxLabel),
.umbraco-forms-field.date label:not(.checkboxLabel),
.umbraco-forms-field.password label:not(.checkboxLabel),
.umbraco-forms-field.dropdown label:not(.checkboxLabel) {
  line-height: 1;
  white-space: normal;
}
.fieldWrapper input:not([type=checkbox]):not([type=radio]),
.fieldWrapper select,
.umbraco-forms-field.shortanswer input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.shortanswer select,
.umbraco-forms-field.date input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.date select,
.umbraco-forms-field.password input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.password select,
.umbraco-forms-field.dropdown input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.dropdown select {
  appearance: none;
  position: relative;
  white-space: normal;
  line-height: 1;
}
.fieldWrapper input::-ms-reveal,
.fieldWrapper input::-ms-clear,
.umbraco-forms-field.shortanswer input::-ms-reveal,
.umbraco-forms-field.shortanswer input::-ms-clear,
.umbraco-forms-field.date input::-ms-reveal,
.umbraco-forms-field.date input::-ms-clear,
.umbraco-forms-field.password input::-ms-reveal,
.umbraco-forms-field.password input::-ms-clear,
.umbraco-forms-field.dropdown input::-ms-reveal,
.umbraco-forms-field.dropdown input::-ms-clear {
  display: none;
}
.fieldWrapper select,
.umbraco-forms-field.shortanswer select,
.umbraco-forms-field.date select,
.umbraco-forms-field.password select,
.umbraco-forms-field.dropdown select {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWF4WU1pZCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOiM0NDQ7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5hcnJvd3M8L3RpdGxlPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQuOTUiIGhlaWdodD0iMTAiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMS40MSA0LjY3IDIuNDggMy4xOCAzLjU0IDQuNjcgMS40MSA0LjY3Ii8+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjMuNTQgNS4zMyAyLjQ4IDYuODIgMS40MSA1LjMzIDMuNTQgNS4zMyIvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 25px 50px;
}
.fieldWrapper select::-ms-expand,
.umbraco-forms-field.shortanswer select::-ms-expand,
.umbraco-forms-field.date select::-ms-expand,
.umbraco-forms-field.password select::-ms-expand,
.umbraco-forms-field.dropdown select::-ms-expand {
  display: none;
}
.fieldWrapper .description,
.umbraco-forms-field.shortanswer .description,
.umbraco-forms-field.date .description,
.umbraco-forms-field.password .description,
.umbraco-forms-field.dropdown .description {
  margin-top: 6px;
  font-size: 0.84em;
  opacity: 0.92;
}
.fieldWrapper .description > *:first-child,
.umbraco-forms-field.shortanswer .description > *:first-child,
.umbraco-forms-field.date .description > *:first-child,
.umbraco-forms-field.password .description > *:first-child,
.umbraco-forms-field.dropdown .description > *:first-child {
  margin-top: 0 !important;
}
.fieldWrapper .description > *:last-child,
.umbraco-forms-field.shortanswer .description > *:last-child,
.umbraco-forms-field.date .description > *:last-child,
.umbraco-forms-field.password .description > *:last-child,
.umbraco-forms-field.dropdown .description > *:last-child {
  margin-bottom: 0 !important;
}

.fieldWrapper textarea,
.umbraco-forms-field.longanswer textarea {
  appearance: none;
  position: relative;
  white-space: normal;
  line-height: 1;
}

.controlWrapper,
.adg-umbraco-forms-controlwrapper {
  border-bottom: 2px solid #ebebec;
  margin-bottom: 15px;
  padding-bottom: 10px;
}
.controlWrapper:last-child,
.adg-umbraco-forms-controlwrapper:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.controlWrapper > *:first-child,
.adg-umbraco-forms-controlwrapper > *:first-child {
  margin-top: 0 !important;
}
.controlWrapper > *:last-child,
.adg-umbraco-forms-controlwrapper > *:last-child {
  margin-bottom: 0 !important;
}
.controlWrapper .control,
.adg-umbraco-forms-controlwrapper .control {
  display: flex;
  align-items: center;
}
.controlWrapper label,
.adg-umbraco-forms-controlwrapper label {
  font-size: 1.2em;
  white-space: nowrap;
  cursor: pointer;
}
.controlWrapper .description,
.adg-umbraco-forms-controlwrapper .description {
  margin-top: 6px;
  font-size: 0.84em;
  opacity: 0.92;
}
.controlWrapper .description > *:first-child,
.adg-umbraco-forms-controlwrapper .description > *:first-child {
  margin-top: 0 !important;
}
.controlWrapper .description > *:last-child,
.adg-umbraco-forms-controlwrapper .description > *:last-child {
  margin-bottom: 0 !important;
}

.fieldWrapper.enrolleeName {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: -1;
}

.styledFieldset,
.umbraco-forms-fieldset {
  position: relative;
  border: 0px;
  padding: 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  padding-top: 10px;
}
.styledFieldset legend,
.styledFieldset .groupLegend,
.umbraco-forms-fieldset legend,
.umbraco-forms-fieldset .groupLegend {
  padding: 8px 10px 4px 10px;
  border-radius: 4px;
  background: #081E3f;
  color: #FFCC00;
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8em;
}
.styledFieldset .groupLegend,
.umbraco-forms-fieldset .groupLegend {
  display: inline-block;
  margin-bottom: 9px;
}
.styledFieldset .fieldsetGrid,
.umbraco-forms-fieldset .fieldsetGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.styledFieldset .fieldsetGrid .fieldWrapper,
.styledFieldset .fieldsetGrid .controlWrapper,
.umbraco-forms-fieldset .fieldsetGrid .fieldWrapper,
.umbraco-forms-fieldset .fieldsetGrid .controlWrapper {
  margin: 0;
}
.styledFieldset > :last-child,
.umbraco-forms-fieldset > :last-child {
  margin-bottom: 0 !important;
}

.fieldWrapper,
.umbraco-forms-field.shortanswer,
.umbraco-forms-field.date,
.umbraco-forms-field.password,
.umbraco-forms-field.dropdown {
  margin: 10px auto;
}
.fieldWrapper label:not(.checkboxLabel),
.umbraco-forms-field.shortanswer label:not(.checkboxLabel),
.umbraco-forms-field.date label:not(.checkboxLabel),
.umbraco-forms-field.password label:not(.checkboxLabel),
.umbraco-forms-field.dropdown label:not(.checkboxLabel) {
  position: absolute;
  top: 10px;
  left: 9px;
  z-index: 2;
  display: block;
  margin: 0 0 5px;
  border: 0;
  padding: 0;
  font-size: 0.8em;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: #54545a;
}
.fieldWrapper input:not([type=checkbox]):not([type=radio]),
.fieldWrapper select,
.umbraco-forms-field.shortanswer input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.shortanswer select,
.umbraco-forms-field.date input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.date select,
.umbraco-forms-field.password input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.password select,
.umbraco-forms-field.dropdown input:not([type=checkbox]):not([type=radio]),
.umbraco-forms-field.dropdown select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 52px;
  margin: 0;
  border: 2px solid #1654b0;
  padding: 23px 8px 6px;
  border-radius: 4px;
  background: #fff;
  color: #222;
  text-align: left;
}
.fieldWrapper select,
.umbraco-forms-field.shortanswer select,
.umbraco-forms-field.date select,
.umbraco-forms-field.password select,
.umbraco-forms-field.dropdown select {
  padding-top: 24px;
}
.fieldWrapper button,
.umbraco-forms-field.shortanswer button,
.umbraco-forms-field.date button,
.umbraco-forms-field.password button,
.umbraco-forms-field.dropdown button {
  position: absolute !important;
  color: #fff;
  top: 0;
  right: 0;
  height: 53px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transform: scale(0.8);
  border-radius: 4px;
  background: #081E3f;
  border: none;
  padding: 0;
  cursor: pointer;
}
.fieldWrapper button[aria-pressed=true] .icon:after,
.umbraco-forms-field.shortanswer button[aria-pressed=true] .icon:after,
.umbraco-forms-field.date button[aria-pressed=true] .icon:after,
.umbraco-forms-field.password button[aria-pressed=true] .icon:after,
.umbraco-forms-field.dropdown button[aria-pressed=true] .icon:after {
  content: "";
  width: 3px;
  height: 20px;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  transform: skewX(-45deg);
  background: #fff;
}
.fieldWrapper button .icon,
.umbraco-forms-field.shortanswer button .icon,
.umbraco-forms-field.date button .icon,
.umbraco-forms-field.password button .icon,
.umbraco-forms-field.dropdown button .icon {
  font-size: 1.2em;
}

.fieldWrapper textarea,
.umbraco-forms-field.longanswer textarea {
  position: relative;
  display: block;
  width: 100%;
  min-height: 52px;
  margin: 0;
  border: 2px solid #1654b0;
  padding: 6px 8px 6px;
  border-radius: 4px;
  background: #fff;
  color: #222;
  text-align: left;
}

form .error,
.formWrap .error {
  display: block;
  margin: 4px 0;
  padding: 2px 4px;
  text-align: center;
  background-color: #E0001E;
  color: white;
}

@media (max-width: 670px) {
  .styledFieldset .fieldsetGrid {
    grid-template-columns: none;
  }
}
@media (max-width: 520px) {
  .controlWrapper label {
    font-size: 1em;
    font-weight: 700;
    white-space: normal;
  }
}
@media (max-width: 400px) {
  .controlWrapper label {
    font-size: 0.9em;
  }
}
.buffer {
  overflow: auto;
  min-height: calc(100vh - 40px);
}

footer {
  position: relative;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background: #000;
}
footer .adgLogoWrap a {
  display: block;
  background: #000 !important;
}
footer .adgLogoWrap .adgLogo {
  height: 30px;
  width: auto;
}

.guestLoginMethods .socialMediaTitle {
  margin-bottom: 15px;
  font-weight: 400;
}
.guestLoginMethods .socialMediaLogins {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.guestLoginMethods .socialMediaLogins .facebookLogin {
  background: #1877f2 !important;
  color: white !important;
}
.guestLoginMethods .socialMediaLogins .googleLogin {
  background: #ebebec !important;
  color: black !important;
}
.guestLoginMethods .socialMediaLogins .twitterLogin {
  background: #14171a !important;
  color: white !important;
}
.guestLoginMethods .socialMediaLogins .twitterLogin .text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.guestLoginMethods .socialMediaLogins .twitterLogin .svgIcon {
  margin: 0 auto;
}
.guestLoginMethods .socialMediaLogins .appleLogin {
  background: black !important;
  color: white !important;
}
.guestLoginMethods .socialMediaLogins > a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  border-radius: 8px;
  height: 45px;
  text-align: center;
  padding: 1em 3em !important;
}
.guestLoginMethods .socialMediaLogins > a .svgIcon {
  width: 16px;
  height: auto;
  margin-right: 7px;
}
.guestLoginMethods .socialMediaLogins > a .text {
  font-weight: bold;
  font-size: 1.1em;
}
.guestLoginMethods .socialMediaLogins .appleLogin {
  height: 45px;
  cursor: pointer;
}
.guestLoginMethods .socialMediaLogins .appleLogin > div {
  max-width: 100% !important;
}

.loginWrapper[data-hidden-for-guest=true] {
  display: none !important;
}

.guestWrapperHolder {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 800ms;
  text-align: left;
}

.fieldWrapper.enrolleeName {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: -1;
}

header {
  position: relative;
  z-index: 120;
  display: flex;
  margin: 20px auto 0;
  padding: 0px 50px;
}
header .branding {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  padding: 5px 30px 0px 0px;
  border-left: 1px solid #f8c93e;
}
header .branding h1 {
  margin: 0;
}
header .logoWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 275px;
  padding: 0px 10px 10px;
  border-bottom: 1px solid #f8c93e;
}
header .logo {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
header .orientationTitle {
  line-height: 1.1em;
  font-size: 1.5em;
  color: #fff;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 15px 20px;
  font-weight: 500;
}

#membership {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  margin: 50px auto 50px;
  padding: 20px;
  border: 1px solid #f8c93e;
}
#membership .membershipInner {
  background: #fff;
}
#membership .titleArea {
  position: relative;
  padding: 20px 20px;
  text-align: center;
  color: #fff;
  background: #081E3f;
}
#membership .titleArea h2 {
  font-weight: 700;
  font-family: "furoreregular", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 2em;
  letter-spacing: 0.015em;
}
#membership .titleArea.accountProfiler {
  font-size: 1.5em;
}
#membership .titleArea h2 {
  margin: 0;
}
#membership .titleArea p {
  margin: 0;
  padding-top: 10px;
}
#membership .titleArea button.returnToSocial {
  display: none;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  padding: 5px;
  border: 0;
  background: none;
  color: white;
  cursor: pointer;
  transition: all 200ms;
}
#membership .titleArea button.returnToSocial .text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .titleArea button.returnToSocial:hover {
    transform: translateY(-50%) scale(1.3);
  }
}
[data-browser=ie] #membership .titleArea button.returnToSocial:hover {
  transform: translateY(-50%) scale(1.3);
}
.ie #membership .titleArea button.returnToSocial:hover {
  transform: translateY(-50%) scale(1.3);
}
#membership .titleArea button.returnToSocial.focus-visible {
  transform: translateY(-50%) scale(1.3);
}
#membership .titleArea button.returnToSocial:focus-visible {
  transform: translateY(-50%) scale(1.3);
}
#membership .errorMessage {
  text-align: center;
  background: #020812;
  color: #fff;
  border-radius: 10px;
  font-weight: 600;
  padding: 5px 10px;
}
#membership .loginNav {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #ebebec;
  margin-top: 30px;
  padding-top: 10px;
  text-align: center;
  font-size: 0.9em;
}
#membership .loginNav > * {
  margin: 0 5px;
}
#membership .formWrap {
  margin-top: 10px;
  padding: 25px 40px;
  border-radius: 0px;
  box-shadow: 0 0.8px 1.7px rgba(0, 0, 0, 0.014), 0 1.9px 4.1px rgba(0, 0, 0, 0.02), 0 3.6px 7.8px rgba(0, 0, 0, 0.025), 0 6.5px 13.8px rgba(0, 0, 0, 0.03), 0 12.1px 25.9px rgba(0, 0, 0, 0.036), 0 29px 62px rgba(0, 0, 0, 0.05);
  text-align: center;
  background-color: #fff;
  color: #081E3f;
}
#membership .formWrap input[type=radio] {
  position: relative;
  margin: 0;
  box-shadow: none;
  cursor: pointer;
}
@supports (appearance: none) {
  #membership .formWrap input[type=radio] {
    appearance: none;
    width: 1em;
    height: 1em;
  }
  #membership .formWrap input[type=radio]:before {
    content: "";
    display: block;
    width: 1em;
    height: 1em;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(34, 34, 34, 0.7);
    border-radius: 50%;
    transition: all 200ms;
  }
  #membership .formWrap input[type=radio]:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    width: 0;
    height: 0;
    opacity: 0;
    border-radius: 50%;
    transition: all 200ms;
  }
  #membership .formWrap input[type=radio]:checked:before {
    border-color: #081E3f;
    background-color: #081E3f;
  }
  #membership .formWrap input[type=radio]:checked:after {
    opacity: 1;
    background-color: white;
    width: 0.45em;
    height: 0.45em;
  }
  #membership .formWrap input[type=radio][disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  #membership .formWrap input[type=radio][disabled]:before {
    background-color: #e1e1e1;
  }
}
#membership .formWrap input[type=checkbox] {
  position: relative;
  margin: 0;
  cursor: pointer;
}
@supports (appearance: none) {
  #membership .formWrap input[type=checkbox] {
    appearance: none;
    width: 1em;
    height: 1em;
  }
  #membership .formWrap input[type=checkbox]:before {
    content: "";
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 3px;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(34, 34, 34, 0.7);
    transition: all 200ms;
  }
  #membership .formWrap input[type=checkbox]:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    display: block;
    background: transparent url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9 IjAgMCAzMTYuMzggMjUwLjYiPjxwYXRoIGQ9Ik03MC40IDIxOS44YzQuNTUgNC44 NyA5LjIyIDkuNjcgMTQuMTIgMTQuMTkgMy43NSAzLjQ3IDcuNzEgNi44NSAxMS45 MyA5Ljc0IDUuNSAzLjc4IDExLjkyIDcuMDggMTguNzggNi44NSA2LjY2LS4yMyAx Mi43Ny0zLjczIDE3Ljk3LTcuNjIgOC42LTYuNDIgMTYuNjctMTUuMTcgMjMuODQt MjMuMTRMMzA1LjY4IDU0LjY5YzcuMi04IDExLjc5LTE4LjI1IDEwLjQ3LTI5LjE3 LTEuMDQtOC41OS01LjcxLTE2LjU1LTEzLjAxLTIxLjI5LTUuNjUtMy42Ny0xMi4y Ni00Ljk4LTE4LjktMy44Mi04LjkgMS41Ni0xNi41NCA3LjIyLTIyLjQ4IDEzLjc4 LTQ4LjM0IDUzLjMyLTk2LjU5IDEwNi43MS0xNDQuODUgMTYwLjExbC0zLjE5IDMu NTMtNjAuNC02Ni42M2MtOC40Mi05LjI5LTE5LjctMTMuOTQtMzEuOTItOS41Ny00 LjcyIDEuNjktOC45OSA0LjU4LTEyLjQ1IDguMTktMy44NiA0LjA0LTYuNzggOS4w Ni04LjEzIDE0LjQ5LTIuMzEgOS4yNy4zIDE4LjA3IDYuNjIgMjUuMTNsNjIuOTUg NzAuMzVaIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQiLz48L3N2Zz4K") no-repeat;
    background-size: 70%;
    background-position: 50% 50%;
    transition: all 200ms;
    opacity: 0;
    filter: invert(1);
  }
  #membership .formWrap input[type=checkbox]:checked:before {
    border-color: #081E3f;
    background-color: #081E3f;
    border-color: #081E3f;
  }
  #membership .formWrap input[type=checkbox]:checked:after {
    opacity: 1;
  }
  #membership .formWrap input[type=checkbox][disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  #membership .formWrap input[type=checkbox][disabled]:before {
    background-color: rgba(34, 34, 34, 0.8);
  }
}
#membership .formWrap input[type=checkbox] ~ label,
#membership .formWrap input[type=radio] ~ label {
  padding-left: 0.35em;
}
#membership .formWrap.login .horzDivider {
  display: flex;
  align-items: center;
  margin: 25px 0;
}
#membership .formWrap.login .horzDivider .line {
  height: 2px;
  flex-grow: 1;
  background: #ebebec;
}
#membership .formWrap.login .horzDivider .or {
  text-transform: uppercase;
  color: #222;
  margin-inline: 15px;
}
#membership .formWrap.login button.continueWithEmail {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px 8px;
  border: 0px;
  line-height: 1.1em;
  text-align: center;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  outline-offset: 0;
  text-decoration: none;
  transition: all 0.15s;
  background-color: #081E3f;
  color: white;
  width: 100%;
  padding: 1.3em 1.8em;
  transform: none !important;
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 500px;
  margin: 0 auto;
  gap: 5px;
  position: relative;
}
#membership .formWrap.login button.continueWithEmail .icon {
  font-family: "fiu-eel";
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.login button.continueWithEmail:hover {
    text-decoration: none;
    background-color: #FFCC00;
    color: #081E3f;
    cursor: pointer;
  }
}
[data-browser=ie] #membership .formWrap.login button.continueWithEmail:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.ie #membership .formWrap.login button.continueWithEmail:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.login button.continueWithEmail.focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.login button.continueWithEmail:focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.login button.continueWithEmail:focus-visible {
  outline: 2px solid #f8c93e;
}
#membership .formWrap.login button.continueWithEmail[disabled], #membership .formWrap.login button.continueWithEmail[aria-disabled=true] {
  background: #54545a;
  color: #fff;
  text-decoration: none;
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.login button.continueWithEmail:hover:after {
    width: 100%;
  }
}
[data-browser=ie] #membership .formWrap.login button.continueWithEmail:hover:after {
  width: 100%;
}
.ie #membership .formWrap.login button.continueWithEmail:hover:after {
  width: 100%;
}
#membership .formWrap.login button.continueWithEmail.focus-visible:after {
  width: 100%;
}
#membership .formWrap.login button.continueWithEmail:focus-visible:after {
  width: 100%;
}
#membership .formWrap.login button.continueWithEmail:after {
  height: 10px;
  background: linear-gradient(90deg, #00FFFF, #FFCC00);
  width: 100%;
  position: absolute;
  top: calc(100% + 5px);
  left: 0px;
  content: "";
  width: 0%;
  transition: width 0.2s;
}
#membership .formWrap.login button.continueWithEmail .icon {
  font-size: 1.2em;
}
#membership .formWrap.login button.continueWithEmail .text {
  font-size: 1.2em;
  padding-top: 5px;
}
#membership .formWrap.login .loginWrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 8px;
}
#membership .formWrap.login .returningWrapper {
  width: calc(60% - 20px);
  text-align: center;
}
#membership .formWrap.login .newAccountWrapper {
  width: 40%;
  position: relative;
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.login .newAccountWrapper:hover:after {
    width: 100%;
  }
}
[data-browser=ie] #membership .formWrap.login .newAccountWrapper:hover:after {
  width: 100%;
}
.ie #membership .formWrap.login .newAccountWrapper:hover:after {
  width: 100%;
}
#membership .formWrap.login .newAccountWrapper.focus-visible:after {
  width: 100%;
}
#membership .formWrap.login .newAccountWrapper:focus-visible:after {
  width: 100%;
}
#membership .formWrap.login .newAccountWrapper:after {
  height: 10px;
  background: linear-gradient(90deg, #00FFFF, #FFCC00);
  width: 100%;
  position: absolute;
  top: calc(100% + 5px);
  left: 0px;
  content: "";
  width: 0%;
  transition: width 0.2s;
}
#membership .formWrap.login .newAccountWrapper > a:first-child {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px 8px;
  border: 0px;
  line-height: 1.1em;
  text-align: center;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  outline-offset: 0;
  text-decoration: none;
  transition: all 0.15s;
  background-color: #081E3f;
  color: white;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
#membership .formWrap.login .newAccountWrapper > a:first-child .icon {
  font-family: "fiu-eel";
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.login .newAccountWrapper > a:first-child:hover {
    text-decoration: none;
    background-color: #FFCC00;
    color: #081E3f;
    cursor: pointer;
  }
}
[data-browser=ie] #membership .formWrap.login .newAccountWrapper > a:first-child:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.ie #membership .formWrap.login .newAccountWrapper > a:first-child:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.login .newAccountWrapper > a:first-child.focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.login .newAccountWrapper > a:first-child:focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.login .newAccountWrapper > a:first-child:focus-visible {
  outline: 2px solid #f8c93e;
}
#membership .formWrap.login .newAccountWrapper > a:first-child[disabled], #membership .formWrap.login .newAccountWrapper > a:first-child[aria-disabled=true] {
  background: #54545a;
  color: #fff;
  text-decoration: none;
}
#membership .formWrap.login .newAccountWrapper > a:first-child[data-type=Student] .icon:before {
  text-transform: lowercase;
  content: "z";
}
#membership .formWrap.login .newAccountWrapper > a:first-child .icon {
  font-size: 2.5em;
  margin-bottom: 10px;
}
#membership .formWrap.login .newAccountWrapper > a:first-child .text {
  font-weight: bold;
  font-size: 1.15em;
}
#membership .formWrap.landingPage {
  padding-top: 40px;
  padding-bottom: 40px;
}
#membership .formWrap.landingPage .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
#membership .formWrap.landingPage a {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px 8px;
  border: 0px;
  line-height: 1.1em;
  text-align: center;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  outline-offset: 0;
  text-decoration: none;
  transition: all 0.15s;
  background-color: #081E3f;
  color: white;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 6px;
  font-size: 1.4em;
  text-decoration: none !important;
  min-height: 175px;
  background-size: 120%;
  background-position: center 25%;
  position: relative;
  transition: all 0.2s;
  padding-bottom: 10px;
  border-radius: 0px;
  border: 1px solid #f8c93e;
}
#membership .formWrap.landingPage a .icon {
  font-family: "fiu-eel";
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.landingPage a:hover {
    text-decoration: none;
    background-color: #FFCC00;
    color: #081E3f;
    cursor: pointer;
  }
}
[data-browser=ie] #membership .formWrap.landingPage a:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.ie #membership .formWrap.landingPage a:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.landingPage a.focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.landingPage a:focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.landingPage a:focus-visible {
  outline: 2px solid #f8c93e;
}
#membership .formWrap.landingPage a[disabled], #membership .formWrap.landingPage a[aria-disabled=true] {
  background: #54545a;
  color: #fff;
  text-decoration: none;
}
#membership .formWrap.landingPage a:before {
  content: "";
  background: #081E3f;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.2s;
}
#membership .formWrap.landingPage a:after {
  height: 10px;
  background: linear-gradient(90deg, #00FFFF, #FFCC00);
  width: 100%;
  position: absolute;
  top: calc(100% + 5px);
  left: 0px;
  content: "";
  width: 0%;
  transition: width 0.2s;
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.landingPage a:hover {
    background-size: 135%;
    background-position: center 30%;
  }
  #membership .formWrap.landingPage a:hover .text {
    color: #f8c93e;
  }
  #membership .formWrap.landingPage a:hover:before {
    opacity: 0;
  }
  #membership .formWrap.landingPage a:hover:after {
    width: 100%;
  }
}
[data-browser=ie] #membership .formWrap.landingPage a:hover {
  background-size: 135%;
  background-position: center 30%;
}
[data-browser=ie] #membership .formWrap.landingPage a:hover .text {
  color: #f8c93e;
}
[data-browser=ie] #membership .formWrap.landingPage a:hover:before {
  opacity: 0;
}
[data-browser=ie] #membership .formWrap.landingPage a:hover:after {
  width: 100%;
}
.ie #membership .formWrap.landingPage a:hover {
  background-size: 135%;
  background-position: center 30%;
}
.ie #membership .formWrap.landingPage a:hover .text {
  color: #f8c93e;
}
.ie #membership .formWrap.landingPage a:hover:before {
  opacity: 0;
}
.ie #membership .formWrap.landingPage a:hover:after {
  width: 100%;
}
#membership .formWrap.landingPage .text {
  font-weight: bold;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  background: #081E3f;
  color: #f8c93e;
  color: #fff;
  text-align: center;
  padding: 10px 15px 5px;
  z-index: 5;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.9em;
}
#membership .formWrap.landingPage .icon {
  font-family: "fiu-eel";
  position: absolute;
  bottom: 20px;
  right: 5px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #f8c93e;
  z-index: 6;
  color: #081E3f;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#membership .formWrap.landingPage .icon:before {
  content: "m";
}
#membership .formWrap.accountProfiler {
  text-align: left;
}
#membership .formWrap.accountProfiler .instruction {
  margin-bottom: 15px;
  text-align: center;
}
#membership .formWrap.accountProfiler select {
  margin: 0 0 0 5px;
  border: 5px solid #ebebec;
  background: #ebebec;
  font-weight: 700;
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.accountProfiler select:hover {
    cursor: pointer;
  }
}
[data-browser=ie] #membership .formWrap.accountProfiler select:hover {
  cursor: pointer;
}
.ie #membership .formWrap.accountProfiler select:hover {
  cursor: pointer;
}
#membership .formWrap.accountProfiler .buttonHolder {
  margin: 20px 0 20px;
}
#membership .formWrap.accountProfiler .resetAccountTypeBtn {
  border: 0;
  padding: 0;
  background: none;
  border-radius: 0;
  overflow: visible;
  font-size: 0.8em;
  border-bottom: 1px solid #081E3f;
  cursor: pointer;
  color: #081E3f;
  background-color: white;
  transition: 200ms;
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.accountProfiler .resetAccountTypeBtn:hover {
    border-color: #f8c93e;
    color: #f8c93e;
    background-color: black;
  }
}
[data-browser=ie] #membership .formWrap.accountProfiler .resetAccountTypeBtn:hover {
  border-color: #f8c93e;
  color: #f8c93e;
  background-color: black;
}
.ie #membership .formWrap.accountProfiler .resetAccountTypeBtn:hover {
  border-color: #f8c93e;
  color: #f8c93e;
  background-color: black;
}
#membership .formWrap.accountProfiler .resetAccountTypeBtn.focus-visible {
  border-color: #f8c93e;
  color: #f8c93e;
  background-color: black;
}
#membership .formWrap.accountProfiler .resetAccountTypeBtn:focus-visible {
  border-color: #f8c93e;
  color: #f8c93e;
  background-color: black;
}
#membership .formWrap.twoFactor .singleFieldSubmission {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}
#membership .formWrap.twoFactor .singleFieldSubmission .fieldWrapper {
  margin: 0 5px 0 0;
}
#membership .formWrap.twoFactor .singleFieldSubmission button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px 8px;
  border: 0px;
  line-height: 1.1em;
  text-align: center;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  outline-offset: 0;
  text-decoration: none;
  transition: all 0.15s;
  background-color: #081E3f;
  color: white;
}
#membership .formWrap.twoFactor .singleFieldSubmission button .icon {
  font-family: "fiu-eel";
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.twoFactor .singleFieldSubmission button:hover {
    text-decoration: none;
    background-color: #FFCC00;
    color: #081E3f;
    cursor: pointer;
  }
}
[data-browser=ie] #membership .formWrap.twoFactor .singleFieldSubmission button:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.ie #membership .formWrap.twoFactor .singleFieldSubmission button:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.twoFactor .singleFieldSubmission button.focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.twoFactor .singleFieldSubmission button:focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.twoFactor .singleFieldSubmission button:focus-visible {
  outline: 2px solid #f8c93e;
}
#membership .formWrap.twoFactor .singleFieldSubmission button[disabled], #membership .formWrap.twoFactor .singleFieldSubmission button[aria-disabled=true] {
  background: #54545a;
  color: #fff;
  text-decoration: none;
}
#membership .formWrap.twoFactor #TFAButtonApp {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px 8px;
  border: 0px;
  line-height: 1.1em;
  text-align: center;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  outline-offset: 0;
  text-decoration: none;
  transition: all 0.15s;
  background-color: #081E3f;
  color: white;
  padding: 0.35em 0.5em;
  margin-bottom: 20px;
}
#membership .formWrap.twoFactor #TFAButtonApp .icon {
  font-family: "fiu-eel";
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .formWrap.twoFactor #TFAButtonApp:hover {
    text-decoration: none;
    background-color: #FFCC00;
    color: #081E3f;
    cursor: pointer;
  }
}
[data-browser=ie] #membership .formWrap.twoFactor #TFAButtonApp:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.ie #membership .formWrap.twoFactor #TFAButtonApp:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.twoFactor #TFAButtonApp.focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.twoFactor #TFAButtonApp:focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .formWrap.twoFactor #TFAButtonApp:focus-visible {
  outline: 2px solid #f8c93e;
}
#membership .formWrap.twoFactor #TFAButtonApp[disabled], #membership .formWrap.twoFactor #TFAButtonApp[aria-disabled=true] {
  background: #54545a;
  color: #fff;
  text-decoration: none;
}
#membership .formWrap.twoFactor #TFAButtonEmail,
#membership .formWrap.twoFactor #OTPButton {
  border: 0;
  background: none;
  padding: 0 0 2px;
  margin-top: 15px;
  border-bottom: 1px solid #081E3f;
  color: #081E3f;
  background-color: white;
  cursor: pointer;
}
#membership .formWrap.twoFactor #TFAButtonEmail[data-wait-a-few=true],
#membership .formWrap.twoFactor #OTPButton[data-wait-a-few=true] {
  opacity: 0.6;
  cursor: not-allowed;
}
#membership .buttonHolder {
  text-align: center;
}
#membership .buttonHolder button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px 8px;
  border: 0px;
  line-height: 1.1em;
  text-align: center;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  outline-offset: 0;
  text-decoration: none;
  transition: all 0.15s;
  background-color: #081E3f;
  color: white;
  margin: 0 auto;
}
#membership .buttonHolder button .icon {
  font-family: "fiu-eel";
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .buttonHolder button:hover {
    text-decoration: none;
    background-color: #FFCC00;
    color: #081E3f;
    cursor: pointer;
  }
}
[data-browser=ie] #membership .buttonHolder button:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.ie #membership .buttonHolder button:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .buttonHolder button.focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .buttonHolder button:focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#membership .buttonHolder button:focus-visible {
  outline: 2px solid #f8c93e;
}
#membership .buttonHolder button[disabled], #membership .buttonHolder button[aria-disabled=true] {
  background: #54545a;
  color: #fff;
  text-decoration: none;
}
#membership .styledFieldset {
  margin-bottom: 20px;
}
#membership .styledFieldset .groupLegend {
  margin-bottom: 10px;
}
#membership .styledFieldset .controlWrapper select {
  padding-right: 30px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
#membership .styledFieldset .controlWrapper > span.dropDownArrow {
  display: inline;
  position: relative;
  height: 30px;
  width: 30px;
  left: -25px;
  pointer-events: none;
}
@media (hover: hover) and (any-pointer: fine) {
  #membership .styledFieldset .controlWrapper > span.dropDownArrow:hover {
    cursor: pointer;
  }
}
[data-browser=ie] #membership .styledFieldset .controlWrapper > span.dropDownArrow:hover {
  cursor: pointer;
}
.ie #membership .styledFieldset .controlWrapper > span.dropDownArrow:hover {
  cursor: pointer;
}
#membership .styledFieldset .controlWrapper > span.dropDownArrow:after {
  content: "w";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  font-family: "fiu-eel";
  font-size: 1em;
  color: #222;
}

.simpleDialog .closeDialog {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px 8px;
  border: 0px;
  line-height: 1.1em;
  text-align: center;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  outline-offset: 0;
  text-decoration: none;
  transition: all 0.15s;
  background-color: #081E3f;
  color: white;
}
.simpleDialog .closeDialog .icon {
  font-family: "fiu-eel";
}
@media (hover: hover) and (any-pointer: fine) {
  .simpleDialog .closeDialog:hover {
    text-decoration: none;
    background-color: #FFCC00;
    color: #081E3f;
    cursor: pointer;
  }
}
[data-browser=ie] .simpleDialog .closeDialog:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.ie .simpleDialog .closeDialog:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.simpleDialog .closeDialog.focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.simpleDialog .closeDialog:focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.simpleDialog .closeDialog:focus-visible {
  outline: 2px solid #f8c93e;
}
.simpleDialog .closeDialog[disabled], .simpleDialog .closeDialog[aria-disabled=true] {
  background: #54545a;
  color: #fff;
  text-decoration: none;
}
.simpleDialog .closeDialog .icon {
  display: none;
}

#resetData button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px 8px;
  border: 0px;
  line-height: 1.1em;
  text-align: center;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  outline-offset: 0;
  text-decoration: none;
  transition: all 0.15s;
  background-color: #081E3f;
  color: white;
}
#resetData button .icon {
  font-family: "fiu-eel";
}
@media (hover: hover) and (any-pointer: fine) {
  #resetData button:hover {
    text-decoration: none;
    background-color: #FFCC00;
    color: #081E3f;
    cursor: pointer;
  }
}
[data-browser=ie] #resetData button:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
.ie #resetData button:hover {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#resetData button.focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#resetData button:focus-visible {
  text-decoration: none;
  background-color: #FFCC00;
  color: #081E3f;
  cursor: pointer;
}
#resetData button:focus-visible {
  outline: 2px solid #f8c93e;
}
#resetData button[disabled], #resetData button[aria-disabled=true] {
  background: #54545a;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 900px) {
  #membership {
    max-width: calc(100% - 100px);
  }
}
@media (max-width: 820px) {
  #membership .formWrap.landingPage {
    padding: 30px 25px 40px;
  }
  #membership .formWrap.login .loginWrapper {
    flex-direction: column-reverse;
  }
  #membership .formWrap.login .loginWrapper .newAccountWrapper {
    width: 100%;
    margin-top: 20px;
    border-top: 1px solid #f8c93e;
    padding-top: 15px;
  }
  #membership .formWrap.login .loginWrapper .newAccountWrapper a {
    padding: 10px;
  }
  #membership .formWrap.login .loginWrapper .returningWrapper {
    width: 100%;
  }
  #membership .titleArea h2 {
    font-size: 1.75em;
  }
}
@media (max-width: 670px) {
  .guestLoginMethods .socialMediaLogins {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  header .branding {
    border-left: 0px;
    align-items: center;
    padding-right: 0px;
  }
  header .orientationTitle {
    font-size: 1.3em;
  }
  #membership {
    margin-top: 15px;
    max-width: calc(100% - 40px);
  }
  #membership .formWrap {
    padding: 25px;
  }
  #membership .formWrap.createAccount {
    padding: 0px 25px 25px;
  }
  #membership .titleArea h2 {
    font-size: 1.4em;
    letter-spacing: 0.025em;
  }
  #membership .formWrap.login .newAccountWrapper {
    width: auto;
  }
  #membership .formWrap.landingPage {
    padding: 20px 20px 30px;
  }
  #membership .formWrap.landingPage .buttons {
    flex-direction: column;
    max-width: 375px;
    width: 100%;
    margin: 0 auto;
  }
  #membership .formWrap.landingPage .buttons a {
    width: 100%;
  }
  #membership .formWrap.landingPage .buttons a:first-child {
    margin-bottom: 15px;
  }
  #resetData button {
    width: 100%;
    margin-bottom: 8px;
  }
}
@media (max-width: 520px) {
  #membership .titleArea.accountProfiler {
    font-size: 1.125em;
  }
}
@media (max-width: 400px) {
  #membership .formWrap {
    padding: 16px;
  }
  #membership .formWrap.landingPage {
    padding: 20px;
  }
}
.rte h4,
.rte h5 {
  margin: 0.8em 0 0.5em;
}
.rte > *:first-child {
  margin-top: 0 !important;
}
.rte > *:last-child {
  margin-bottom: 0 !important;
}

.rte p {
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
}
.rte h3.heading {
  margin: 25px 0 10px;
  font-size: 1.65em;
  font-weight: 800;
  color: #081E3f;
  border-bottom: 1px solid #f8c93e;
  padding-bottom: 3px;
}
.rte h3.heading + .headingList {
  margin-top: 25px;
}
.rte h4.subheading {
  margin: 25px 0 0px;
  font-size: 1.3em;
  font-weight: 600;
  color: #081E3f;
  padding-bottom: 3px;
}
.rte h4.subheading + .headingList {
  margin-top: 25px;
}
.rte .headingList,
.rte .subheadingList {
  margin: 20px 0 10px;
  padding-bottom: 3px;
  border: double 2px #f8c93e;
  border-width: 0px 0px 4px 0px;
  font-weight: 500;
  color: #081E3f;
  background-color: white;
  font-size: 1.05em;
  line-height: 1.2em;
}
.rte .largeText {
  position: relative;
  margin-bottom: 25px;
  display: block;
  font-size: 1.5em;
  line-height: 1.4em;
  font-family: "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
}
.rte .highlight {
  background-color: #ebebec;
  color: #081E3f;
}
.rte ul {
  padding: 0;
}
.rte ul > li {
  margin-left: 20px;
  margin-bottom: 5px;
}
.rte ul > li::marker {
  font-size: 1.2em;
  color: #081E3f;
}
.rte ol {
  padding: 0;
  list-style-type: none;
  counter-reset: counter;
}
.rte ol > li {
  position: relative;
  counter-increment: counter;
  margin-left: 40px;
  margin-bottom: 5px;
}
.rte ol > li:before {
  content: counter(counter) ". ";
  position: absolute;
  right: calc(100% + 8px);
  color: #081E3f;
  font-weight: bold;
}
.rte.calloutBold {
  margin: 30px 0px;
  padding: 10px;
  border-radius: 0px;
  background: #fff;
  border: 1px solid #f8c93e;
  color: #081E3f;
  background-image: radial-gradient(ellipse at top left, #D92D8A 20%, #FFCC00 80%);
  font-weight: 600;
}
.rte.calloutBold .inner {
  border: 1px solid #f8c93e;
  padding: 15px;
  background: #fff;
}
.rte.calloutBold .inner > *:first-child {
  margin-top: 0 !important;
}
.rte.calloutBold .inner > *:last-child {
  margin-bottom: 0 !important;
}
.rte.calloutSubtle {
  margin: 25px auto;
  padding: 20px 20px 0px;
  position: relative;
  width: 100%;
  font-weight: 500;
}
.rte.calloutSubtle .inner > *:first-child {
  margin-top: 0 !important;
}
.rte.calloutSubtle .inner > *:last-child {
  margin-bottom: 0 !important;
}
.rte.calloutSubtle:before, .rte.calloutSubtle:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
}
.rte.calloutSubtle:before {
  width: 40px;
  height: 5px;
  background: transparent linear-gradient(90deg, #00FFFF, #FFCC00) 0% 0% no-repeat padding-box;
}
.rte.calloutSubtle:after {
  height: 40px;
  width: 5px;
  background: transparent linear-gradient(180deg, #00FFFF, #FFCC00) 0% 0% no-repeat padding-box;
}

#membership .formWrap.maintenance {
  background: #f8c93e;
  border-color: #fff;
}
#membership .formWrap.maintenance .formArea {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: transparent;
}
#membership .formWrap.maintenance .formArea .rte {
  color: #fff;
}
#membership .formWrap.maintenance .formArea .icon {
  flex-shrink: 0;
  flex-grow: 0;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  background: #081E3f;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  margin-bottom: 20px;
}

@media (max-width: 670px) {
  #membership .formWrap.maintenance {
    padding: 15px;
  }
  #membership .formWrap.maintenance .formArea {
    flex-direction: column-reverse;
    justify-content: center;
  }
  #membership .formWrap.maintenance .formArea .icon {
    width: 55px;
    height: 55px;
    font-size: 2.25em;
    padding: 0;
    margin-bottom: 15px;
  }
}
.membershipPage .errorPage .titleArea {
  margin-bottom: 30px;
}
.membershipPage .errorPage .errorWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.membershipPage .errorPage .errorIconWrap {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(ellipse at top left, #D92D8A 20%, #FFCC00 80%);
  padding: 10px;
}
.membershipPage .errorPage .icon {
  font-size: 5em;
  display: block;
  text-align: center;
  background: #FFCC00;
  border: 4px solid #fff;
  color: #081E3f;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.membershipPage .errorPage .rte {
  text-align: center;
  padding: 50px 20px 40px 20px;
  color: #081E3f;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}
.membershipPage .errorPage .rte h3.heading {
  background: transparent;
  color: #081E3f;
  text-transform: none;
}

@media (max-width: 820px) {
  .membershipPage .errorPage .icon {
    font-size: 4em;
    width: 100px;
    height: 100px;
    border-width: 2px;
  }
}
details {
  transition: all 500ms;
}

details > summary::marker,
details > summary::-webkit-details-marker {
  display: none;
}

.accordionModule .accordionTitle {
  cursor: pointer;
  list-style: none;
}

.accordionModule {
  position: relative;
  margin: 10px 0;
}
.accordionModule:first-of-type {
  margin-top: 20px;
}
.accordionModule:last-of-type {
  margin-bottom: 20px;
}
.accordionModule[open] {
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
}
.accordionModule[open] > .accordionTitle .icon {
  transform: rotate(90deg);
  transform-origin: center;
}
.accordionModule .accordionTitle {
  position: relative;
  background-color: #081E3f;
  color: white;
  padding: 18px 20px 15px 40px;
  border-radius: 0px;
  font-size: 1.2em;
  background: linear-gradient(153deg, rgb(8, 30, 63) 0%, rgb(11, 41, 86) 100%);
  color: #fff;
  font-weight: 500;
  color: #FFCC00;
}
@media (hover: hover) and (any-pointer: fine) {
  .accordionModule .accordionTitle:hover {
    color: #00FFFF;
  }
  .accordionModule .accordionTitle:hover .icon {
    left: 18px;
  }
}
[data-browser=ie] .accordionModule .accordionTitle:hover {
  color: #00FFFF;
}
[data-browser=ie] .accordionModule .accordionTitle:hover .icon {
  left: 18px;
}
.ie .accordionModule .accordionTitle:hover {
  color: #00FFFF;
}
.ie .accordionModule .accordionTitle:hover .icon {
  left: 18px;
}
.accordionModule .accordionTitle.focus-visible {
  color: #00FFFF;
}
.accordionModule .accordionTitle.focus-visible .icon {
  left: 18px;
}
.accordionModule .accordionTitle:focus-visible {
  color: #00FFFF;
}
.accordionModule .accordionTitle:focus-visible .icon {
  left: 18px;
}
.accordionModule .accordionTitle:focus-visible {
  color: #00FFFF;
  outline: 0px;
}
.accordionModule .accordionTitle .icon {
  position: absolute;
  left: 15px;
  transform: rotate(0);
  transform-origin: 4px 50%;
  transition: 0.2s transform, left 0.2s;
  font-size: 0.8em;
}
.accordionModule .accordionTitle .icon:before {
  content: "m";
}
.accordionModule .accordionWrapper {
  padding: 20px 20px;
  border-radius: 0px 0px 0px 0px;
  background: #fff;
  will-change: height;
}