@forward "globalVariables.scss";
$iconFont: "fiu-eel";

// Misc.
$shadow: 0 0.8px 1.7px rgba(0, 0, 0, 0.014), 0 1.9px 4.1px rgba(0, 0, 0, 0.02), 0 3.6px 7.8px rgba(0, 0, 0, 0.025), 0 6.5px 13.8px rgba(0, 0, 0, 0.03), 0 12.1px 25.9px rgba(0, 0, 0, 0.036),
  0 29px 62px rgba(0, 0, 0, 0.05);
$radius: 4px;

// Elements
$progress: 20px;
$headerHeight: 90px;
$innerHeader: 90px;

$footerHeight: 45px;
$infoSize: 50px;
$smHeader: 60px;
$maxWidth: 100%;
$toolBar: 55px;
$progressW: 370px;
$border: 5px;
$homeBtn: 50px;

$sb: 50px;
$slideTop: 200px;

//Based on the logo, adjust this max width for ADA responsive
$logoADAMax: 275px;
