@forward "mixinLibrary-04";
@use "mixinLibrary-04" as *;
@use "variables" as *;
@use "sass:math";

/// Accessible backgrounds! Ensure your elements always have proper contrast by setting both background-color and color properties.
/// @author Ben Richardson
/// @param {string} $bg - The background to use for the element.
/// @param {string} $textDark [dark] - Text color to use when element's background is above a certain threshold in lightness.
/// @param {string} $textLight [white] - Text color to use when element's background is below a certain threshold in lightness.
@mixin bgWithContrast($bg, $textDark: $primary, $textLight: white) {
  background-color: $bg;

  $color-brightness: round((red($bg) * 299) + (green($bg) * 587) + math.div((blue($bg) * 114), 1000));
  $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + math.div((blue(#ffffff) * 114), 1000));

  @if abs($color-brightness) < math.div($light-color, 2) {
    color: $textLight;
  } @else {
    color: $textDark;
  }
}

@mixin bgPos() {
  &[data-bg-pos="top left"] {
    background-position: 0% 0%;
  }
  &[data-bg-pos="top middle"] {
    background-position: 50% 0;
  }
  &[data-bg-pos="top right"] {
    background-position: 100% 0%;
  }
  &[data-bg-pos="center left"] {
    background-position: 0% 50%;
  }
  &[data-bg-pos="center"] {
    background-position: 50% 50%;
  }
  &[data-bg-pos="center right"] {
    background-position: 100% 50%;
  }
  &[data-bg-pos="bottom left"] {
    background-position: 0% 100%;
  }
  &[data-bg-pos="bottom middle"] {
    background-position: 50% 100%;
  }
  &[data-bg-pos="bottom right"] {
    background-position: 100% 100%;
  }
}

@mixin squareButton(){
  position: relative;
  border: 2px solid $secondary;
  color: $primary;
  @include flexCenter;
  font-size: 1em;
  height: 50px;
  width: 50px;
  background: transparent;
  line-height: 1em;
  .icon{
    line-height: 1em;
  }

  @include hoverAndFocus {
    @include bgWithContrast($yellow);
    border-color: $yellow;
    cursor: pointer;
  }
}

@mixin button($color: $primary, $hoverColor: $yellow) {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px 8px;
  border: 0px;
  line-height: 1.1em;
  text-align: center;
  font-family: $basicFont;
  font-weight: 400;
  outline-offset: 0;
  text-decoration: none;
  transition: all 0.15s;
  @include bgWithContrast($color);
  .icon {
    font-family: $iconFont;
  }

  @include hoverAndFocus {
    text-decoration: none;
    @include bgWithContrast($hoverColor);
    cursor: pointer;
    //text-decoration: underline;
  }
  &:focus-visible{
    outline: 2px solid $secondary;
  }
  &[disabled],
  &[aria-disabled="true"] {
    background: $darkGrey;
    color: $light;
    text-decoration: none;
  }
}

@mixin linearGradient($color1:$aqua, $color2: $yellow,$deg: 90deg){
  background:  transparent linear-gradient($deg, $color1, $color2 ) 0% 0% no-repeat padding-box;
}

@mixin checkbox($size: 1em, $borderColor: $basic, $selectedColor: $primary, $selectedBorder: $primary) {
  position: relative;
  margin: 0;
  cursor: pointer;

  @supports (appearance: none) {
    appearance: none;
    width: $size;
    height: $size;

    &:before {
      content: "";
      display: block;
      width: $size;
      height: $size;
      border-radius: 3px;
      border-style: solid;
      border-width: 2px;
      border-color: rgba($borderColor, 70%);

      transition: all $veryFast;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: $size;
      height: $size;

      display: block;

      background: transparent
        url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9 IjAgMCAzMTYuMzggMjUwLjYiPjxwYXRoIGQ9Ik03MC40IDIxOS44YzQuNTUgNC44 NyA5LjIyIDkuNjcgMTQuMTIgMTQuMTkgMy43NSAzLjQ3IDcuNzEgNi44NSAxMS45 MyA5Ljc0IDUuNSAzLjc4IDExLjkyIDcuMDggMTguNzggNi44NSA2LjY2LS4yMyAx Mi43Ny0zLjczIDE3Ljk3LTcuNjIgOC42LTYuNDIgMTYuNjctMTUuMTcgMjMuODQt MjMuMTRMMzA1LjY4IDU0LjY5YzcuMi04IDExLjc5LTE4LjI1IDEwLjQ3LTI5LjE3 LTEuMDQtOC41OS01LjcxLTE2LjU1LTEzLjAxLTIxLjI5LTUuNjUtMy42Ny0xMi4y Ni00Ljk4LTE4LjktMy44Mi04LjkgMS41Ni0xNi41NCA3LjIyLTIyLjQ4IDEzLjc4 LTQ4LjM0IDUzLjMyLTk2LjU5IDEwNi43MS0xNDQuODUgMTYwLjExbC0zLjE5IDMu NTMtNjAuNC02Ni42M2MtOC40Mi05LjI5LTE5LjctMTMuOTQtMzEuOTItOS41Ny00 LjcyIDEuNjktOC45OSA0LjU4LTEyLjQ1IDguMTktMy44NiA0LjA0LTYuNzggOS4w Ni04LjEzIDE0LjQ5LTIuMzEgOS4yNy4zIDE4LjA3IDYuNjIgMjUuMTNsNjIuOTUg NzAuMzVaIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQiLz48L3N2Zz4K")
        no-repeat;
      background-size: 70%;
      background-position: 50% 50%;
      transition: all $veryFast;
      opacity: 0;
      @if (contrast($selectedColor) == white) {
        filter: invert(1);
      }
    }

    &:checked {
      &:before {
        border-color: $selectedColor;
        background-color: $selectedColor;
        border-color: $selectedBorder;
      }

      &:after {
        opacity: 1;
      }
    }

    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;

      &:before {
        background-color: rgba($basic, 0.8);
      }
    }
  }
}

@mixin radio($size: 1em, $selectedColor: $primary, $borderColor: $basic, $selectedBorder: $selectedColor) {
  position: relative;
  margin: 0;
  box-shadow: none;
  cursor: pointer;

  @supports (appearance: none) {
    appearance: none;
    width: $size;
    height: $size;

    &:before {
      content: "";
      display: block;
      width: $size;
      height: $size;
      border-style: solid;
      border-width: 2px;
      border-color: rgba($borderColor, 70%);

      border-radius: 50%;
      transition: all $veryFast;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;

      width: 0;
      height: 0;

      opacity: 0;
      border-radius: 50%;
      transition: all $veryFast;
    }

    &:checked {
      &:before {
        border-color: $selectedBorder;
        background-color: $selectedColor;
      }

      &:after {
        opacity: 1;
        background-color: contrast($selectedColor);
        width: $size * 0.45;
        height: $size * 0.45;
      }
    }

    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;

      &:before {
        background-color: lighten($basic, 75%);
      }
    }
  }
}

@mixin switch($size: 1.25em, $color: $basic, $selectedColor: $primary) {
  position: relative;
  margin: 0;
  cursor: pointer;

  @supports (appearance: none) {
    $knobSize: calc(#{$size} - 8px);
    appearance: none;
    width: 1.25em * 2;
    height: $size;
    border-radius: 50px;
    border: 2px solid $color;
    background-color: $light;
    transition: all $fast;

    /* The toggle knob */
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      border-radius: 50%;
      width: $knobSize;
      height: $knobSize;
      background: $color;
      transition: all $fast;
    }

    /* Change color and position when checked */
    &:checked {
      background: $selectedColor;
      border: 2px solid $selectedColor;
      &:after {
        left: calc(100% - #{$knobSize} - 2px);
        background: contrast($selectedColor);
      }
    }

    /* Drop the opacity of the toggle knob when the input is disabled */
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

@mixin subtleGradient($color, $deg: 128deg) {
  background-image: linear-gradient($deg, $color 40%, darken($color, 5%) 100%);
}

@mixin resetButton() {
  background: transparent;
  border: 0px;
  box-shadow: none;
  outline: 0px;
  display: block;
  text-align: unset;
  align-items: unset;
  padding: 0px;
  cursor: pointer;
  color: unset;
}

@mixin buttonADA($color: $primary, $hoverColor: $secondary) {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  border: 2px solid $lightGrey;

  line-height: 1.1em;
  text-align: center;
  border-radius: $radius;
  font-family: $basicFont;
  outline-offset: 0;
  text-decoration: none;
  transition: all $veryFast;
  @include bgWithContrast($color);
  font-weight: 600;

  &[disabled],
  &[aria-disabled="true"] {
    opacity: 0.5;
  }

  @include hoverAndFocus {
    text-decoration: none;
    &:not([disabled]):not([aria-disabled="true"]) {
      @include bgWithContrast($hoverColor);
      border-color: $lightGrey;
      cursor: pointer;
    }
  }
}
