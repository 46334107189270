@use "mixins" as *;
@use "variables" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

$radius: 0px;
.buffer {
  overflow: auto;
  min-height: calc(100vh - 40px);
}

footer {
  position: relative;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background: #000;

  .adgLogoWrap {
    a {
      display: block;
      background: #000 !important;
    }

    .adgLogo {
      height: 30px;
      width: auto;
    }
  }
}

.guestLoginMethods {
  .socialMediaTitle {
    margin-bottom: 15px;
    font-weight: 400;
  }

  .socialMediaLogins {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    .facebookLogin {
      background: #1877f2 !important;
      color: white !important;
    }
    .googleLogin {
      background: #ebebec !important;
      color: black !important;
    }
    .twitterLogin {
      background: #14171a !important;
      color: white !important;
      .text {
        @include screenReader;
      }
      .svgIcon {
        margin: 0 auto;
      }
    }
    .appleLogin {
      background: black !important;
      color: white !important;
    }
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none !important;
      border-radius: 8px;
      height: 45px;
      text-align: center;
      padding: 1em 3em !important;

      .svgIcon {
        width: 16px;
        height: auto;
        margin-right: 7px;
      }
      .text {
        font-weight: bold;
        font-size: 1.1em;
      }
    }
    .appleLogin {
      height: 45px;
      cursor: pointer;
      > div {
        max-width: 100% !important;
      }
    }
  }
}

.loginWrapper[data-hidden-for-guest="true"] {
  display: none !important;
}

.guestWrapperHolder {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all $slow;
  text-align: left;
}

//These style is just used for the Honeypot feature. These styles keep the field from being seen by humans and they should not be changed or removed.
.fieldWrapper.enrolleeName {
  opacity: 0;
  @include absolute;
  width: 0;
  height: 0;
  z-index: -1;
}

// END BASE STYLES //

header {
  position: relative;
  z-index: 120;
  display: flex;
  margin: 20px auto 0;
  padding: 0px 50px;

  .branding {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    padding: 5px 30px 0px 00px;
    border-left: 1px solid $secondary;

    h1 {
      margin: 0;
    }
  }

  .logoWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 275px;
    padding: 0px 10px 10px;
    border-bottom: 1px solid $secondary;
  }

  .logo {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .orientationTitle {
    line-height: 1.1em;
    font-size: 1.5em;
    color: $light;
    font-family: $secondaryFont;
    padding: 15px 20px;
    font-weight: 500;
  }
}

#membership {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  margin: 50px auto 50px;
  padding: 20px;
  border: 1px solid $secondary;
  .membershipInner {
    background: $light;
  }

  .titleArea {
    position: relative;
    padding: 20px 20px;
    text-align: center;
    color: $light;
    background: $primary;
    h2 {
      font-weight: 700;
      font-family: $primaryFont;
      font-size: 2em;
      letter-spacing: 0.015em;
    }

    &.accountProfiler {
      font-size: 1.5em;
    }

    h2 {
      margin: 0;
    }

    p {
      margin: 0;
      padding-top: 10px;
    }

    button.returnToSocial {
      display: none;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      padding: 5px;
      border: 0;
      background: none;
      color: contrast($primary);
      cursor: pointer;
      transition: all $veryFast;
      .text {
        @include screenReader;
      }
      @include hoverAndFocus {
        transform: translateY(-50%) scale(1.3);
      }
    }
  }

  .errorMessage {
    text-align: center;
    background: darken($primary, 10%);
    color: $light;
    border-radius: 10px;
    font-weight: 600;
    padding: 5px 10px;
  }

  .loginNav {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid $lightGrey;
    margin-top: 30px;
    padding-top: 10px;
    text-align: center;
    font-size: 0.9em;

    > * {
      margin: 0 5px;
    }
  }

  .formWrap {
    margin-top: 10px;
    padding: 25px 40px;
    border-radius: $radius;
    box-shadow: $shadow;
    text-align: center;
    @include bgWithContrast($light);

    input[type="radio"] {
      @include radio;
    }

    input[type="checkbox"] {
      @include checkbox;
    }

    input[type="checkbox"] ~ label,
    input[type="radio"] ~ label {
      padding-left: 0.35em;
    }
  }

  .formWrap.login {
    .horzDivider {
      display: flex;
      align-items: center;
      margin: 25px 0;

      .line {
        height: 2px;
        flex-grow: 1;
        background: $lightGrey;
      }

      .or {
        text-transform: uppercase;
        color: $basic;
        margin-inline: 15px;
      }
    }

    button.continueWithEmail {
      @include button($primary, $yellow);
      width: 100%;

      padding: 1.3em 1.8em;
      transform: none !important;
      text-decoration: none !important;
      @include flexCenter;
      max-width: 500px;
      margin: 0 auto;
      gap: 5px;
      position: relative;
      @include hoverAndFocus {
        &:after {
          width: 100%;
        }
      }

      &:after {
        height: 10px;
        background: $gradient;
        width: 100%;
        position: absolute;
        top: calc(100% + 5px);
        left: 0px;
        content: "";
        width: 0%;
        transition: width 0.2s;
      }
      .icon {
        font-size: 1.2em;
        // margin-bottom: 10px;
      }
      .text {
        font-size: 1.2em;
        padding-top: 5px;
      }
    }

    .loginWrapper {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 8px;
    }

    .returningWrapper {
      width: calc(60% - 20px);
      text-align: center;
    }

    .newAccountWrapper {
      width: 40%;
      position: relative;
      @include hoverAndFocus {
        &:after {
          width: 100%;
        }
      }

      &:after {
        height: 10px;
        background: $gradient;
        width: 100%;
        position: absolute;
        top: calc(100% + 5px);
        left: 0px;
        content: "";
        width: 0%;
        transition: width 0.2s;
      }

      > a:first-child {
        @include button;
        flex-direction: column;
        width: 100%;
        height: 100%;

        &[data-type="Student"] {
          .icon:before {
            text-transform: lowercase;
            content: "z";
          }
        }

        .icon {
          font-size: 2.5em;
          margin-bottom: 10px;
        }

        .text {
          font-weight: bold;
          font-size: 1.15em;
        }
      }
    }
  }

  .formWrap.landingPage {
    padding-top: 40px;
    padding-bottom: 40px;
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      @include button;
      flex-direction: column;
      flex-grow: 1;
      margin: 0 6px;
      font-size: 1.4em;
      text-decoration: none !important;
      min-height: 175px;
      background-size: 120%;
      background-position: center 25%;
      position: relative;
      transition: all 0.2s;
      padding-bottom: 10px;
      border-radius: 0px;
      border: 1px solid $secondary;
      &:before {
        content: "";
        background: $primary;
        opacity: 0.4;
        @include absolute;
        @include fillSpace;
        transition: opacity 0.2s;
        //   border-radius: 5px;
      }
      &:after {
        height: 10px;
        background: $gradient;
        width: 100%;
        position: absolute;
        top: calc(100% + 5px);
        left: 0px;
        content: "";
        width: 0%;
        transition: width 0.2s;
      }

      @include hover {
        background-size: 135%;
        background-position: center 30%;
        .text {
          color: $secondary;
        }
        &:before {
          opacity: 0;
        }
        &:after {
          width: 100%;
        }
      }
    }

    .text {
      font-weight: bold;
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 100%;
      background: $primary;
      color: $secondary;
      color: $light;
      text-align: center;
      padding: 10px 15px 5px;
      z-index: 5;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 0.9em;
      // border-radius: 0px 0px 5px 5px;
    }

    .icon {
      font-family: $iconFont;
      position: absolute;
      bottom: 20px;
      right: 5px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: $secondary;
      z-index: 6;
      color: $primary;
      font-size: 0.9em;
      @include flexCenter;
      &:before {
        content: "m";
      }
    }

    .guestLoginBtn {
      // transform-origin: 25% 50%;
      // .icon:before {
      //   background: url('../images/Roary-2.png') no-repeat;
      //   content: 'k';
      // }
    }

    .enrolleeLoginBtn {
      // transform-origin: 75% 50%;
      // .icon:before {
      //   content: "z";
      // }
    }
  }

  .formWrap.accountProfiler {
    text-align: left;

    .instruction {
      margin-bottom: 15px;
      text-align: center;
    }

    select {
      margin: 0 0 0 5px;
      border: 5px solid $lightGrey;
      background: $lightGrey;
      font-weight: 700;

      @include hover {
        cursor: pointer;
      }
    }

    .buttonHolder {
      margin: 20px 0 20px;
    }

    .resetAccountTypeBtn {
      border: 0;
      padding: 0;
      background: none;
      border-radius: 0;
      overflow: visible;
      font-size: 0.8em;
      border-bottom: 1px solid $primary;
      cursor: pointer;
      @include colorWithContrast($primary);
      transition: $veryFast;

      @include hoverAndFocus {
        border-color: $secondary;
        @include colorWithContrast($secondary);
      }
    }
  }

  .formWrap.twoFactor {
    .singleFieldSubmission {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;

      .fieldWrapper {
        margin: 0 5px 0 0;
      }

      button {
        @include button;
      }
    }

    #TFAButtonApp {
      @include button;
      padding: 0.35em 0.5em;
      margin-bottom: 20px;
    }

    #TFAButtonEmail,
    #OTPButton {
      border: 0;
      background: none;
      padding: 0 0 2px;
      margin-top: 15px;
      border-bottom: 1px solid $primary;
      @include colorWithContrast($primary);
      cursor: pointer;

      &[data-wait-a-few="true"] {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  .buttonHolder {
    text-align: center;

    button {
      @include button;
      margin: 0 auto;
    }
  }

  .styledFieldset {
    margin-bottom: 20px;

    .groupLegend {
      margin-bottom: 10px;
    }

    .controlWrapper {
      select {
        padding-right: 30px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
      }

      > span {
        &.dropDownArrow {
          display: inline;
          position: relative;
          height: 30px;
          width: 30px;
          left: -25px;
          pointer-events: none;

          @include hover {
            cursor: pointer;
          }

          &:after {
            content: "w";
            @include vertCenter;
            font-family: $iconFont;
            font-size: 1em;
            color: $basic;
          }
        }
      }
    }
  }
}

.simpleDialog {
  .closeDialog {
    @include button;

    .icon {
      display: none;
    }
  }
}

#resetData {
  button {
    @include button;
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //

@media (max-width: $md) {
  #membership {
  }
}

@media (max-width: $tween) {
  // header {
  //   height: auto;
  //   .branding {
  //     flex-direction: column;
  //     .logoWrap {
  //       justify-content: center;
  //       width: 100%;
  //       max-width: 280px;
  //       flex: auto;
  //       margin-bottom: 10px;
  //     }
  //     .orientationTitle {
  //       text-align: center;
  //     }
  //   }
  // }
  // #membership {
  //   .formWrap {
  //     &.login {
  //       .loginWrapper {
  //         flex-direction: column;
  //         gap: 15px;
  //       }
  //       .newAccountWrapper {
  //         width: auto;
  //         margin: auto;
  //       }
  //       .returningWrapper {
  //         width: auto;
  //       }
  //     }
  //     &.landingPage {
  //       .buttons {
  //         a {
  //           flex-grow: 1;
  //         }
  //       }
  //     }
  //   }
  // }

  #membership {
    max-width: calc(100% - 100px);
  }
}

@media (max-width: $sm) {
  #membership {
    .formWrap {
      &.landingPage {
        padding: 30px 25px 40px;
      }
      &.login {
        .loginWrapper {
          flex-direction: column-reverse;

          .newAccountWrapper {
            width: 100%;
            margin-top: 20px;
            border-top: 1px solid $secondary;
            padding-top: 15px;
            a {
              padding: 10px;
            }
          }
          .returningWrapper {
            width: 100%;
          }
        }
      }
    }
    .titleArea {
      h2 {
        font-size: 1.75em;
      }
    }
  }
}

@media (max-width: $xs) {
  .guestLoginMethods .socialMediaLogins {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  header {
    .branding {
      border-left: 0px;
      align-items: center;
      padding-right: 0px;
    }
    .orientationTitle {
      font-size: 1.3em;
    }
  }
  #membership {
    margin-top: 15px;
    max-width: calc(100% - 40px);
    .formWrap {
      padding: 25px;
      &.createAccount {
        padding: 0px 25px 25px;
      }
    }
    .titleArea {
      h2 {
        font-size: 1.4em;
        letter-spacing: 0.025em;
      }
    }

    .formWrap.login .newAccountWrapper {
      width: auto;
    }
    .formWrap.landingPage {
      padding: 20px 20px 30px;
      .buttons {
        flex-direction: column;
        max-width: 375px;
        width: 100%;
        margin: 0 auto;
        a {
          width: 100%;

          &:first-child {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  #resetData {
    button {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}

@media (max-width: $xxs) {
  #membership .titleArea.accountProfiler {
    font-size: 1.125em;
  }
  header {
    .orientationTitle {
    }
  }
}

@media (max-width: $tiny) {
  #membership .formWrap {
    padding: 16px;
  }
  #membership {
    .formWrap.landingPage {
      padding: 20px;
    }
  }
}
