@use "mixins" as *;
@use "variables" as *;

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: all $medium, top .75s;

  .bgImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    @include bgPos();
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $lightGrey;
  }

  &:before {
    @include pseudoContent;
    position:absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: rgba($black, .65);
  // background: transparent linear-gradient(90deg, hsla(328, 69%, 51%, 0.65) 0%, hsla(45, 93%, 61%, 0.65) 100%) 0% 0% no-repeat padding-box;
    transition: opacity $fast, width 0.5s;
    opacity: 1;
    z-index: 10;
  }
  &:after {
    @include pseudoContent;
    position:absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: rgba($black, .65);
 
    transition: opacity $fast, width 0.5s;
    opacity: 1;
    z-index: 10;
  }

  .inSection & {
      top: 125%;
  }
  [data-is-loading="true"] & {
    opacity: 0;
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //
@media (max-width: $md) {
 
}

@media (max-width: $xs) {

}
