@use "variables" as *;
@use "mixins" as *;

// BEGIN BASE STYLES - DO NOT CHANGE THESE UNLESS YOU KNOW WHAT YOU'RE DOING //

details {
  // If you change this transition speed, you'll have to change it at the top of Accordion.ts
  transition: all $medium;
}

details > summary::marker, /* Latest Chrome, Edge, Firefox */
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

.accordionModule {
  .accordionTitle {
    cursor: pointer;
    list-style: none;
  }
}

// END BASE STYLES //

.accordionModule {
  position: relative;
  margin: 10px 0;
  &:first-of-type{
    margin-top: 20px;
  }
  &:last-of-type{
    margin-bottom: 20px;
  }

  &[open] {
    box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.3);
    > .accordionTitle {
    //  box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.3);

      .icon {
        transform: rotate(90deg);
        transform-origin: center;
      }
    }
  }

  .accordionTitle {
    position: relative;
    @include bgWithContrast($primary);
    padding: 18px 20px 15px 40px;
    border-radius: 0px;
    font-size: 1.2em;
    background: linear-gradient(153deg, rgba(8,30,63,1) 0%, rgba(11,41,86,1) 100%);
    color:$light;
    font-weight: 500;
    color:$yellow;
    // background: $light;
    // border: 1px solid $primary;
    // color:$primary;

    @include hoverAndFocus {
      color:$aqua;
      .icon{
        left: 18px;
      }
    }

    &:focus-visible {
      color:$aqua;
      outline: 0px;
    }

    .icon {
      position: absolute;
      left: 15px;
      transform: rotate(0);
      transform-origin: 4px 50%;
      transition: 0.2s transform, left .2s;
      font-size: .8em;
      &:before{
        content: 'm';
      }
    }
  }

  .accordionWrapper {
    padding: 20px 20px;
    border-radius: 0px 0px 0px 0px;
    background: $light;
    will-change: height;
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //
