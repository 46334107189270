@use "mixins" as *;
@use "variables" as *;

.membershipPage .errorPage {
  .titleArea {
    margin-bottom: 30px;
  }
  .errorWrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .errorIconWrap{
    position: relative;
    width: 150px;
    height: 150px;
        display: flex;
    justify-content: center;
    align-items: center;
    background: $pinkGradientRadial;
    padding: 10px;
  }

  .icon {
    font-size: 5em;
    display: block;
    text-align: center;
    background: $yellow;
    border: 4px solid $light;
    color:$primary;
    @include fillSpace;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .rte {
    text-align: center;
    padding: 50px 20px 40px 20px;
    color:$primary;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    h3.heading {
      background: transparent;
      color: $primary;
      text-transform: none;
    }
  }
}

@media (max-width: $sm) {
  .membershipPage .errorPage {
    .icon {
      font-size: 4em;
      width: 100px;
      height: 100px;
      border-width: 2px;
    }
  }
}
