//BRIGHTS
$pink: #D92D8A;
$aqua: #00FFFF;
$yellow: #FFCC00;

// Colors
$primary: #081E3f; // Blue
$secondary: #f8c93e; // Yellow Gold
$accent: #b6862c; //Gold Gold
$alt: #00adef; // Light Blue
$altBlue: #0b2956; //Hover Blue
$contrast: #E8CD7D; //
$bright: aqua;
$link: $primary;  //
$primaryHover: lighten($primary, 15%);
$secondaryHover: darken($secondary, 12%);
$gradient: linear-gradient(90deg, #00FFFF, #FFCC00);
$gradientRev: linear-gradient(-90deg, #00FFFF, #FFCC00);
$pinkGradient: transparent linear-gradient(90deg, hsla(328, 69%, 51%, 0.65) 0%, hsla(45, 93%, 61%, 0.65) 100%) 0% 0% no-repeat padding-box;
$pinkGradientRadial: radial-gradient(ellipse at top left, $pink 20%, $yellow 80%);
$aquaGradientRadial: radial-gradient(circle at bottom right, #00FFFF 10%, #FFCC00 55%, #00FFFF 75%);

$hover:#23527c;
$btnHover: $secondary;

$primaryHover: $hover;
$secondaryHover: $hover;

$light: #fff; // White
$lightGrey: #ebebec; // Light Grey
$grey: #a9a9ad; // Grey (50~%, unsuitable for use with text)
$darkGrey: #54545a; // Dark Grey (25~%)
$black: #000000; // Black
$basic: #222; // Dark Grey (10~%)

$link: $primary;

//Hover color can be a 3rd color is school has one
$hover: darken($primary, 10%);
//ADA hover needs to be a color that contrasts with
//the primary color but also is dark enough to see white against.
$ADAhover: $basic;

$wrong: #f02121; //Red
$wrong: #E0001E;
$correct: $primary; // Green


@font-face {
    font-family: 'furoreregular';
    src: url('../fonts/furore-webfont.eot');
    src: url('../fonts/furore-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/furore-webfont.woff2') format('woff2'),
         url('../fonts/Furore-webfont.woff') format('woff'),
         url('../fonts/Furore-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}


// Fonts
$primaryFont: "furoreregular", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$secondaryFont:  "new-frank", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif; //400,500,700,800
$basicFont:  "new-frank","HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif; //400,500,700,800

$genericFont: "Arial", sans-serif;

// Widths
$tiny: 400px;
$xxs: 520px;
$xs: 670px;
$sm: 820px;
$tween: 900px;
$md: 1080px;
$mid: 1160px;
$lg: 1280px;
$xl: 1500px;

$mdH: 675px;
$smH: 600px;
$xsH: 500px;
$tinyH: 405px;

// Animation Speeds - these also need to be changed in animation.ts if modified
$veryFast: 200ms;
$fast: 300ms;
$medium: 500ms;
$slow: 800ms;
$verySlow: 1000ms;
$glacial: 3000ms;
