@use "variables" as *;
@use "mixins" as *;

// Box sizing rules
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Focus styles
* {
  &:focus-visible,  
  &.focus-visible {
    outline-offset: 4px;
    outline: 2px dashed $aqua;
    //box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em $primary;
  }
}

// Remove all animations and transitions for people that prefer not to see them
// However, we're no longer reading the browser preference and relying solely on the user's stored preference.
// Keeping this code commented out for posterity because the javascript logic was tricky and in case we need it again.
// @media (prefers-reduced-motion: reduce) {
//   body:not([data-reduce-animation="false"]) {
//     *,
//     *::before,
//     *::after {
//       animation-duration: 0.01ms !important;
//       animation-iteration-count: 1 !important;
//       transition-duration: 0.01ms !important;
//       scroll-behavior: auto !important;
//     }
//   }
// }

body[data-reduce-animation="true"] {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

html[data-active-zone="slides"],
html[data-active-zone="home"] {
  @include scrollbar($width: 15px, $color: $secondary);
}

html[data-active-zone="slides"] {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.1;
  font-size: 16px;
  font-family: $basicFont;

  position: absolute;
  width: 100%;
  height: 100%;
  background: $primary;
  &.toolsOpen{
    overflow-x: hidden;
  }
  &.menuOut {
    background: $light;
  }
}

// Make images easier to work with
img {
  max-width: 100%;
  display: block;
}

// Natural flow and rhythm in articles by default
article > * + * {
  margin-top: 1em;
}

// Inherit fonts for inputs and buttons
input,
button,
textarea,
select {
  font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

p {
  margin: 0.35em 0 0.75em;
  line-height: 1.55em;
}

ol,
ul {
  text-align: left;
  margin: 0.4em 0 0.75em;
}

li {
  line-height: 1.5em;
}

iframe {
  width: 100%;
  margin-bottom: 10px;
  border: 2px solid $lightGrey;
}

a {
 // text-decoration: none;
  color:$primary;
  text-decoration: underline;
  text-decoration-color:$pink;
  // border-bottom: 2px solid $pink;

  @include hoverAndFocus {
    text-decoration: none;
  }

  .targetBlankNotifier {
    @include screenReader;
  }
}

// MEDIA QUERIES & BROWSER-SPECIFIC ADJUSTMENTS BELOW //
